import { Search } from '@tanstack/react-location';
import { createProvider } from '@frontend/store';

type PathShape<T extends unknown = any> = {
  path: string;
  raw: string;
  search?: Search<unknown>;
  context?: T;
  replace?: boolean;
};

type MainRouterPathShape = Pick<PathShape, 'path' | 'replace' | 'search'>;

type PathHistory = PathShape & { label: string };

export type InterRouterStore<T extends unknown = any> = {
  isSettingsOpen: boolean;
  settingsRouterPath: PathShape;
  mainRouterPath: MainRouterPathShape;
  isSettingsMenuOpen: boolean;
  settingsRouterPathHistory: PathHistory[];
  originPageLabel: string;
  setOriginPageLabel: (label: string | undefined) => void;
  setIsSettingsOpen: (isOpen: boolean) => void;
  setSettingsRouterPathHistory: (path: PathHistory) => void;
  setSettingsRouterPath: (settingsRouterPath: PathShape<T>) => void;
  setMainRouterPath: (mainRouterPath: MainRouterPathShape) => void;
  goBackSettings: () => PathShape | undefined;
  closeSettings: () => void;
  setIsSettingsMenuOpen: (isOpen: boolean) => void;
  context?: T;
};

export const { Provider: InterRouterProvider, useStore: useInterRouter } = createProvider<InterRouterStore>()(
  (set, get) => ({
    context: undefined,
    isSettingsOpen: false,
    isSettingsMenuOpen: false,
    settingsRouterPath: { path: '', raw: '' },
    mainRouterPath: { path: '' },
    settingsRouterPathHistory: [],
    originPageLabel: '',
    setOriginPageLabel: (label) => {
      set(() => ({ originPageLabel: label }));
    },
    setSettingsRouterPath: ({ context, ...settingsRouterPath }) => {
      set((state) => {
        state.context = context;
        return { settingsRouterPath };
      });
    },
    setIsSettingsMenuOpen: (isOpen) => {
      set(() => ({ isSettingsMenuOpen: isOpen }));
    },
    setMainRouterPath: (mainRouterPath) => {
      set(() => ({ mainRouterPath }));
    },
    setSettingsRouterPathHistory: (path) => {
      set((state) => {
        const newHistory = path.replace
          ? [...state.settingsRouterPathHistory.slice(0, -1), path]
          : [...state.settingsRouterPathHistory, path];
        return { settingsRouterPathHistory: newHistory };
      });
    },
    setIsSettingsOpen: (isOpen) => {
      set(() => ({ isSettingsOpen: isOpen }));
    },
    goBackSettings: () => {
      const newHistory = [...get().settingsRouterPathHistory];
      newHistory.pop();
      const newLastMap = newHistory[newHistory.length - 1];
      set(() => ({ settingsRouterPathHistory: newHistory }));

      return newLastMap;
    },
    closeSettings: () => {
      set({ settingsRouterPath: { path: '', raw: '' }, settingsRouterPathHistory: [], isSettingsOpen: false });
      history.pushState('', document.title, location.pathname + location.search);
    },
  })
);
