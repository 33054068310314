import { ReactNode, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { sendInvoiceReceipt } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { useMerchant } from '@frontend/payments-hooks';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { useMultiStepModal } from '@frontend/payments-multistep-modal';
import { PaymentsTerminalController, StoredReader, TerminalReader } from '@frontend/payments-terminal-controller';
import { PickRequired } from '@frontend/types';
import { theme } from '@frontend/theme';
import { DropdownField, Modal, Button, TextField, TextLink, useFormField } from '@frontend/design-system';
import { useInvoicePerson, usePaymentModalContext } from '../hooks';
import { readerConnectErrors, sortReaders } from '../reader-payment';
import { ReceiptSection } from '../receipt';
import { useConnectSelectedReader, useCollectPayment, useTerminalMethods } from './hooks';
import { ReaderStatus } from './reader-status';
import { useTerminalPaymentsContext } from './';

const styles = {
  section: css`
    display: grid;
    grid-template-rows: 1fr auto;
    justify-items: center;
    padding: ${theme.spacing(2)};
  `,
  paymentForm: css`
    flex: 1;
    display: flex;
    gap: ${theme.spacing(2)};
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: ${theme.spacing(50)};
  `,
  buttonBar: css`
    display: flex;
    justify-content: end;
    gap: ${theme.spacing(2)};
  `,
  receiptButtonBar: css`
    display: flex;
    justify-content: space-between;
  `,
};

/**
 * @deprecated Use `CollectionStepReaderPayment` instead, works with server side connection.
 */
export const CollectReaderPayment = () => {
  const { resetSteps, closeModal } = useMultiStepModal();
  const { selectedReader } = useTerminalPaymentsContext();
  const { connectionError } = useTerminalMethods();
  const { invoice } = useSelectedInvoice();
  const { onPaymentSuccess } = usePaymentModalContext() ?? {};
  const { merchantsData } = useMerchant({ allLocations: true });

  const { connectingSelectedReader, connectSelectedReader } = useConnectSelectedReader();
  const { paymentSuccessful, collectPaymentError, setCollectPaymentError, cancelCollectPayment } = useCollectPayment();
  const readerError = connectionError ?? collectPaymentError;

  const handleCancel = async () => {
    await cancelCollectPayment();
    resetSteps();
  };

  useEffect(() => {
    connectSelectedReader();
  }, []);

  useEffect(() => {
    /**
     * Didn't want to add a callback to the `useCollectPayment` hook.
     * Legacy flow is deprecated anyway.
     */

    if (paymentSuccessful) {
      onPaymentSuccess?.();
    }
  }, [paymentSuccessful]);

  const handleTryAgain = () => {
    if (collectPaymentError) setCollectPaymentError(null);
    else if (connectionError) connectSelectedReader();
  };

  const handleEndActivePayment = () => {
    connectSelectedReader(false);
  };

  const isTerminalInUse = !!connectionError?.message.includes(readerConnectErrors.alreadyInUse);

  type RequiredProps = {
    locationId: string | undefined;
    paymentsUrl: (typeof merchantsData)['']['paymentsUrl'];
    selectedReader: typeof selectedReader;
  };

  type AssertRequired<T extends RequiredProps> = { [K in keyof T]: NonNullable<T[K]> };

  const showSelectedReader = (props: RequiredProps): props is AssertRequired<RequiredProps> => {
    const { locationId, paymentsUrl, selectedReader } = props as RequiredProps;
    return !!locationId && !!paymentsUrl && !!selectedReader;
  };

  const showSelectedReaderProps: RequiredProps = {
    locationId: invoice?.locationId,
    paymentsUrl: merchantsData[invoice?.locationId ?? ''].paymentsUrl,
    selectedReader,
  };

  return (
    <CollectReaderPaymentDisplay
      selectedReader={
        showSelectedReader(showSelectedReaderProps)
          ? PaymentsTerminalController.createStoredReader(
              showSelectedReaderProps.locationId,
              showSelectedReaderProps.paymentsUrl,
              PaymentsTerminalController.formatReaderToTerminalReader(showSelectedReaderProps.selectedReader)
            )
          : undefined
      }
      readerError={readerError}
      paymentSuccessful={paymentSuccessful}
      isConnecting={connectingSelectedReader}
      isTerminalInUse={isTerminalInUse}
      handleEndActivePayment={handleEndActivePayment}
      onChangePaymentMethodClick={resetSteps}
      onTryAgainClick={handleTryAgain}
      onCancelClick={handleCancel}
      onCloseClick={closeModal}
    />
  );
};

type InferProps<T extends (args: any) => any> = Parameters<T>[number];
type ReaderStatusProps = InferProps<typeof ReaderStatus>;

type CollectReaderPaymentDisplayProps = {
  selectedReader: StoredReader | undefined;
  onChangePaymentMethodClick: () => void;
  onTryAgainClick: () => void;
  onCancelClick: () => void;
  onCloseClick: () => void;
  ReaderStatusOverrideComponent?: ReactNode;
  availableReaders?: TerminalReader[];
  onSelectedReaderChange?: (reader: TerminalReader) => void;
  disableTerminalSelection?: boolean;
} & PickRequired<ReaderStatusProps, 'readerError' | 'paymentSuccessful'>;

export const CollectReaderPaymentDisplay = ({
  selectedReader,
  readerError,
  paymentSuccessful,
  isConnecting,
  isTerminalInUse,
  handleEndActivePayment,
  onCancelClick,
  onChangePaymentMethodClick,
  onCloseClick,
  onTryAgainClick,
  ReaderStatusOverrideComponent,
  availableReaders,
  onSelectedReaderChange,
  disableTerminalSelection = false,
}: CollectReaderPaymentDisplayProps) => {
  const { t } = useTranslation('payments');

  const readerNameFieldProps = useFormField(
    {
      type: 'text',
      value: selectedReader?.label,
    },
    [selectedReader?.readerId]
  );

  const availableReaderProps = useFormField(
    {
      type: 'dropdown',
      value: selectedReader?.readerId,
    },
    [selectedReader?.readerId]
  );

  useEffect(() => {
    if (availableReaderProps.value) {
      const reader = availableReaders?.find((reader) => reader.id === availableReaderProps.value);

      if (reader && reader?.id !== selectedReader?.readerId) {
        onSelectedReaderChange?.(reader);
      }
    }
  }, [availableReaderProps.value]);

  const sortedAvailableReaders = useMemo(() => sortReaders(availableReaders ?? []), [availableReaders]);

  /// shared
  const { invoice } = useSelectedInvoice();
  const { paymentsUrl } = useMerchant();
  const { personEmail } = useInvoicePerson(invoice);

  const emailReceiptFieldProps = useFormField({
    type: 'email',
    value: personEmail,
  });

  const handleSendReceipt = async () => {
    onCloseClick();
    await sendInvoiceReceipt(paymentsUrl as string, invoice?.id as string, [emailReceiptFieldProps.value]);
  };

  return (
    <>
      <Modal.Body>
        <section css={styles.section}>
          <div css={styles.paymentForm}>
            {sortedAvailableReaders?.length ? (
              <DropdownField
                name={'available-terminals'}
                label={t('Terminal')}
                disabled={disableTerminalSelection}
                {...availableReaderProps}
              >
                {sortedAvailableReaders.map(({ id, label, status }) => (
                  <DropdownField.Option key={id} value={id} disabled={status === 'offline'}>
                    {label}
                  </DropdownField.Option>
                ))}
              </DropdownField>
            ) : (
              selectedReader && <TextField name='reader-name' label='Terminal' {...readerNameFieldProps} readOnly />
            )}
            {ReaderStatusOverrideComponent ? (
              ReaderStatusOverrideComponent
            ) : (
              <ReaderStatus
                readerError={readerError}
                paymentSuccessful={paymentSuccessful}
                isConnecting={isConnecting ?? false}
                isTerminalInUse={isTerminalInUse ?? false}
                handleEndActivePayment={handleEndActivePayment ?? (() => undefined)}
              />
            )}
          </div>
          {!isTerminalInUse && paymentSuccessful && <ReceiptSection emailFieldProps={emailReceiptFieldProps} />}
        </section>
      </Modal.Body>
      <Modal.Footer>
        {!isTerminalInUse &&
          (!paymentSuccessful ? (
            <div css={styles.buttonBar}>
              {readerError ? (
                <>
                  <Button variant='secondary' size='large' onClick={onChangePaymentMethodClick}>
                    {t('Change Payment Method')}
                  </Button>
                  <Button size='large' onClick={onTryAgainClick}>
                    {t('Try Again')}
                  </Button>
                </>
              ) : (
                <Button variant='secondary' size='large' onClick={onCancelClick}>
                  {t('Cancel')}
                </Button>
              )}
            </div>
          ) : (
            <div css={styles.receiptButtonBar}>
              <TextLink onClick={onCloseClick}>{t('Close without receipt')}</TextLink>
              <Button size='large' onClick={handleSendReceipt}>
                {t('Send Receipt')}
              </Button>
            </div>
          ))}
      </Modal.Footer>
    </>
  );
};
