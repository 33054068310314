import { css } from '@emotion/react';

export type BodyProps = React.PropsWithChildren<object>;

const body = css`
  grid-area: content;
`;

export function Body({ children, ...rest }: BodyProps) {
  return (
    <div css={body} {...rest}>
      {children}
    </div>
  );
}
