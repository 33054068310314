import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { useCustomizationFlagShallowStore, CustomizationFlagTypes } from '@frontend/api-customization-flags';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { hasSchemaACL } from '@frontend/auth-helpers';

export const useHasPhoneSystemAccess = () => {
  const { flags } = useCustomizationFlagShallowStore('flags');
  return flags['phonehomeicon']?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;
};

export const useHasPhoneSettingsAccess = (locationIds: string[], parentLocationId?: string) => {
  const { flags } = useCustomizationFlagShallowStore('flags');
  const hasPhonesCustomizationFlag =
    flags['phonehomeicon']?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;

  const hasPhoneFullAccessAcl = parentLocationId
    ? hasSchemaACL(parentLocationId, Permission.PHONE_FULL_ACCESS)
    : hasSchemaACL(locationIds[0], Permission.PHONE_FULL_ACCESS);
  const hasFullPhoneAccess = hasPhoneFullAccessAcl && hasPhonesCustomizationFlag;

  const { aggregateValue: departmentsFlag } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'departments',
    locationIds: locationIds ?? [],
  });

  const { aggregateValue: callRoutesFlag } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'call-routes-settings',
    locationIds: locationIds ?? [],
  });

  const phoneAccess = {
    hasPhoneFullAccessAcl,
    hasPhonesCustomizationFlag,
    hasFullPhoneAccess,
    // Below are the route specific access checks for phone settings routes
    hasMainLineAccess: departmentsFlag && hasPhoneFullAccessAcl,
    hasDepartmentsAccess: departmentsFlag && hasPhoneFullAccessAcl,
    hasOverridesAccess: hasPhonesCustomizationFlag,
    hasOfficeHoursAccess: true,
    hasCallGroupsAccess: hasFullPhoneAccess,
    hasCallQueuesAccess: hasFullPhoneAccess,
    hasCallRoutesAccess: callRoutesFlag && hasFullPhoneAccess,
    hasCallRouteDetailsAccess: callRoutesFlag && hasFullPhoneAccess,
    hasPhoneTreesAccess: hasFullPhoneAccess,
    hasVoicemailBoxesAccess: hasPhonesCustomizationFlag,
    hasAudioLibraryAccess: hasPhonesCustomizationFlag,
    hasCallBlockingAccess: hasFullPhoneAccess,
    hasCallRecordingAccess: hasFullPhoneAccess,
    hasHoldMusicAccess: hasFullPhoneAccess,
    hasDeviceSettingsAccess: hasFullPhoneAccess,
    hasE911AddressesAccess: hasFullPhoneAccess,
    hasContactDirectoriesAccess: hasFullPhoneAccess,
    hasLineKeysAccess: hasFullPhoneAccess,
  };

  return phoneAccess;
};
