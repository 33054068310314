import { useEffect } from 'react';
import { css } from '@emotion/react';
import { init } from '@sentry/react';
import { usePollForUpdates } from '@frontend/automatic-updates';
import envs from '@frontend/env';
import { NotificationComponent } from '@frontend/notifications';
import { CallPopInterface, CallPopWidget } from '@frontend/pop';
import { IPCEventName, IPCRendererCallback, useShell } from '@frontend/shell-utils';
import { sentry } from '@frontend/tracking';
import { WeavePopNotification } from '@frontend/types';
import { theme } from '@frontend/theme';
import { CaretLeftIconSmall, CaretRightIconSmall, IconButton, Text } from '@frontend/design-system';
import { useDebugMode } from './providers/debug-provider';
import { useNotificationManager } from './providers/notification-manager';

init({
  dsn: envs.SENTRY_DSN,
  integrations: [],
});

export const App = () => {
  const shell = useShell();
  const {
    notifications,
    addNotification,
    removeNotification,
    updateNotification,
    currNotificationIndex,
    setCurrNotificationIndex,
    currNotification,

    addPop,
    removePop,
    setPops,
    setCurrPopIndex,
    setPopOutlet,
    popOutlet,
    pops,
  } = useNotificationManager();

  const allowUpdates = pops.length === 0 && notifications.length === 0;
  useAutomaticUpdates(allowUpdates);

  usePingPong();

  const { debugModeEnabled, disableDebugMode } = useDebugMode();
  const hideWindow = useHideWindow();

  useHashChangeListener((hash) => {
    console.info('hash change disabled for now', hash);
  }, []);

  useNotificationShowListener((notification) => {
    console.info('Received Notification Show Event', notification);
    addNotification(notification);
  }, []);

  useNotificationUpdateListener((notification) => {
    console.info('Received Notification Update Event', notification);
    updateNotification(notification);
  }, []);

  useNotificationHideListener((id) => {
    console.info('Received Notification Hide Event', id);
    removeNotification(id);
  }, []);

  useEffect(() => {
    if (!shell.isShell) {
      return;
    }

    /**
     * This callback is used to sync the state of this app with data coming from the main app.
     *
     * Do not use this to emit events to the main app.
     */
    const callback: IPCRendererCallback<IPCEventName.PopAside> = (_e, data) => {
      console.log('Received Pop Aside Event', data);
      if (data.type === 'addNotification') {
        addPop(data.notification);
      }
      if (data.type === 'setNotifications') {
        setPops(data.notifications);
      }

      if (data.type === 'removeNotification') {
        removePop(data.id);
      }

      if (data.type === 'setOutlet') {
        setPopOutlet(data.outlet);
      }

      if (data.type === 'navigate') {
        setCurrPopIndex(data.index);
      }

      if (data.type === 'dismiss') {
        CallPopInterface._dismiss(data.id);
      }
    };

    const id = 'pop-channel' + Math.random().toString();
    shell.on?.(IPCEventName.PopAside, callback, id);
    return () => {
      shell.off?.(IPCEventName.PopAside, callback, id);
    };
  }, [pops, notifications, popOutlet]);

  //syncs up the notifications and pops to make sure the window closes when there are no notifications, and that the index is reset when necessary
  useEffect(() => {
    const isEmpty = notifications.length === 0 && (popOutlet !== 'queue' || pops.length === 0);
    if (isEmpty) {
      hideWindow();
      return;
    }
    if (notifications.length === 1) {
      return setCurrNotificationIndex(0);
    }
    if (notifications.length && currNotificationIndex > notifications.length - 1) {
      return setCurrNotificationIndex(Math.max(0, notifications.length - 1));
    }

    // in case somehow the ipc hide event failed, this will make sure the window doesn't stick around
    console.log('Starting hide interval');
    const interval = setInterval(() => {
      console.log('Checking if notifications are empty', { isEmpty, debugModeEnabled, notifications });
      if (isEmpty) {
        clearInterval(interval);
        return hideWindow();
      }
    }, 3000);
    return () => {
      console.log('Clearing hide interval');
      clearInterval(interval);
    };
  }, [debugModeEnabled, notifications, currNotificationIndex, pops, popOutlet]);

  function checkForGhostClick() {
    const hasContent = notifications.length > 0 || pops.length > 0;

    // Adds a check for ghost click outside of debug mode (in debug mode the window will stay open)
    // and there is nothing wrong with that.
    if (!hasContent && !debugModeEnabled) {
      hideWindow();
      sentry.error({ error: 'Ghost Click Detected', topic: 'phone', severityLevel: 'error' });
      console.error('Ghost Click Detected');
      window.location.reload();
    }
  }

  useDocumentHeightResizer(120, 48);
  const hasSlider = notifications.length > 1;
  console.log({ notifications, pops, popOutlet });
  return (
    <div
      onClick={checkForGhostClick}
      css={css`
        display: inline-block;
        width: auto;
        padding-left: ${theme.spacing(4)};
        padding-right: ${theme.spacing(2)};
        ${debugModeEnabled && `background: white; border: 2px solid black;`}
      `}
    >
      {debugModeEnabled && (
        <div
          css={css`
            padding: 4px;
            background: #f2f2f2;
          `}
        >
          Debug Mode On <button onClick={disableDebugMode}>Turn Off</button>
        </div>
      )}
      {popOutlet === 'queue' && pops.length > 0 && <CallPopWidget notifications={pops} />}
      <div
        css={css`
          border-radius: ${hasSlider ? theme.borderRadius.medium : 0};
          box-shadow: ${hasSlider ? theme.shadows.heavy : 'none'};
        `}
      >
        {hasSlider && (
          <Slider currIndex={currNotificationIndex} total={notifications.length} onChange={setCurrNotificationIndex} />
        )}
        {currNotification && (
          <NotificationComponent
            stacked={hasSlider}
            key={currNotification.id}
            notification={currNotification}
            emit={(action, notification) => {
              if (action.action === 'timed-out') {
                removeNotification(notification.id);
              }

              shell.emit?.('notification:action', {
                action,
                notification,
                //TODO: Somehow it's not associating that the notification type corresponds to the right one.
                // In reality, it does, but I'm struggling to tell typescript that it does. Please send help.
              } as any);
            }}
          />
        )}
      </div>
      {/* <pre>{notifications ? JSON.stringify(notifications, undefined, 2) : 'No Notification Set'}</pre> */}
    </div>
  );
};

const usePingPong = () => {
  const shell = useShell();
  useEffect(() => {
    if (!shell.isShell) {
      return;
    }
    shell.on?.('debug:ping', (_e, payload) => {
      console.info('Received Ping Event', { payload });
      shell.emit?.('debug:pong', payload);
    });
  }, []);
};

const useNotificationShowListener = (
  onShowNotification?: (notification: WeavePopNotification) => void,
  deps?: any[]
) => {
  const shell = useShell();

  useEffect(() => {
    if (!shell.isShell) {
      return;
    }
    const id = 'notification:show' + Math.random();
    const callback: IPCRendererCallback<'notification:show'> = (_e, data) => {
      onShowNotification?.(data.notification);
    };
    shell.on?.('notification:show', callback, id);
    return () => {
      if (callback && id) {
        shell.off?.('notification:show', callback, id);
      }
    };
  }, deps ?? []);
};

const useNotificationUpdateListener = (onUpdate?: (notification: WeavePopNotification) => void, deps?: any[]) => {
  const shell = useShell();
  useEffect(() => {
    if (!shell.isShell) {
      return;
    }
    const id = 'notification:update' + Math.random();
    const callback: IPCRendererCallback<'notification:update'> = (_e, data) => {
      onUpdate?.(data.notification);
    };
    shell.on?.('notification:update', callback, id);
    return () => {
      if (callback && id) {
        shell.off?.('notification:update', callback, id);
      }
    };
  }, deps ?? []);
};

const useNotificationHideListener = (onHideNotification?: (id: string) => void, deps?: any[]) => {
  const shell = useShell();
  useEffect(() => {
    if (!shell.isShell) {
      return;
    }

    const id = 'notification:hide' + Math.random();
    const callback: IPCRendererCallback<'notification:hide'> = (_e, data) => {
      onHideNotification?.(data);
    };
    shell.on?.('notification:hide', callback, id);
    return () => {
      if (callback && id) {
        shell.off?.('notification:hide', callback, id);
      }
    };
  }, deps ?? []);
};

const useHashChangeListener = (onHashChange?: (hash: any) => void, deps?: any[]) => {
  const getHashPayload = () => {
    const hash = window.location.hash;
    if (!window.location.hash) {
      return undefined;
    }
    try {
      return JSON.parse(decodeURIComponent(window.location.hash.substring(1)));
    } catch {
      console.warn('Invalid Hash', hash);
      return undefined;
    }
  };

  useEffect(() => {
    addEventListener('hashchange', (_event) => {
      const hash = getHashPayload();
      onHashChange?.(hash);
    });
  }, deps ?? []);
};

/**
 * keeps the window's height in sync with the document. When the document height changes, emits a resize event to the main process
 * The main process resizes the window accordingly
 */
const useDocumentHeightResizer = (min: number, buffer: number) => {
  const shell = useShell();

  useEffect(() => {
    if (!shell.isShell) {
      return;
    }
    const resizeObserver = new ResizeObserver((entries) => {
      const documentHeight = entries[0].target.clientHeight;
      console.log('Body height changed:', documentHeight);
      shell?.emit?.('notifications:resize', {
        height: Math.max(min, documentHeight + buffer),
        width: undefined,
      });
    });

    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.unobserve(document.body);
    };
  }, []);
};

const useHideWindow = () => {
  const { debugModeEnabled } = useDebugMode();
  const shell = useShell();
  return () => {
    if (!debugModeEnabled) {
      return shell.emit?.('notifications:empty', undefined);
    }
  };
};

type SliderProps = {
  currIndex: number;
  total: number;
  onChange: (i: number) => void;
};
const Slider = (props: SliderProps) => {
  return (
    <div
      css={css`
        display: flex;
        width: 350px;
        background: ${theme.colors.neutral10};
        padding: ${theme.spacing(0.5)};
        justify-content: flex-end;
        align-items: center;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom: 1px solid ${theme.colors.neutral20};
      `}
    >
      <IconButton
        disabled={props.currIndex <= 0}
        size='small'
        label='Previous'
        css={css`
          display: inline-flex;
        `}
        onClick={() => props.onChange(props.currIndex - 1)}
      >
        <CaretLeftIconSmall />
      </IconButton>

      <Text as={'span'}>
        {props.currIndex + 1} / {props.total}
      </Text>
      <IconButton
        disabled={props.currIndex >= props.total - 1}
        size='small'
        label='Next'
        css={css`
          display: inline-flex;
        `}
        onClick={() => props.onChange(props.currIndex + 1)}
      >
        <CaretRightIconSmall />
      </IconButton>
    </div>
  );
};

const useAutomaticUpdates = (allowUpdates: boolean) => {
  const { updateAvailable, currentVersion } = usePollForUpdates(
    10 * 60 * 1000, // 10 minutes
    envs.PUBLISH_URL ? 'https://' + envs.PUBLISH_URL + '/_m/version' : undefined
  );

  useEffect(() => {
    if (updateAvailable && allowUpdates) {
      console.log('Refreshing notification renderer because new version is available (' + currentVersion + ')');
      window.location.reload();
    }
  }, [updateAvailable, allowUpdates]);
};
