import { differenceInMonths, endOfDay, startOfDay, isBefore, startOfMonth, subMonths } from 'date-fns';
import { InvoiceModel, InvoiceModelV3, InvoiceSearchParams } from './types';

/**
 * Limits the date range to be confined to `maxMonths` as calendar months. Returns a limited flag
 * to indicate when the range is truncated.
 *
 * EX: limitDateRange(["2020-7-2", "2020-10-15"], 2) => ["2020-9-1", "2020-10-15"]
 * @param range An array of ISO date strings: [start, end]
 * @param maxMonths The max number months allowed between the dates [start, end]
 */
export const limitDateRange = (range: (string | undefined)[], maxMonths = 3) => {
  const [start, end] = range;

  if ((start && !end) || (!start && end)) {
    throw new Error('Missing date created start or end parameter');
  }

  const result = {
    start,
    end,
    limited: false,
  };

  if (start && end) {
    if (isBefore(new Date(end), new Date(start))) {
      throw new Error('Date created date is before start date');
    }

    if (differenceInMonths(new Date(end), new Date(start)) > maxMonths) {
      result.start = subMonths(startOfMonth(new Date(end)), maxMonths - 1).toISOString();
      result.limited = true;
    }
  }

  return result;
};

/**
 * If the incoming search param is a number or amount (67.00, 67, $67.00), strip the dollar sign off and multiply by 100
 * to get amount in cents.
 * If the incoming amount is not an amount or undefined, pass the incoming argument back.
 * @param initialSearch
 * @returns
 */
export const formatSearchField = (initialSearch?: string): string | undefined => {
  let search = initialSearch;
  if (initialSearch && /^\$?\d*\.?\d*$/gm.test(initialSearch)) {
    const dec = Number.parseFloat(initialSearch.replace(/\$/g, ''));
    const roundedValue = Math.round(dec * 100);
    search = String(roundedValue);
  }
  return search;
};

/**
 * Formats the search params to whats required for the endpoint
 */
export const formatSearchParams = (params: InvoiceSearchParams) => {
  const { isRecorded, submittedOn, search, ...searchParams } = params;

  return {
    ...searchParams,
    ...(!!search && { search: formatSearchField(search) }),
    ...(isRecorded !== undefined && { 'invoice.payment.recorded': isRecorded }),
    ...(!!submittedOn && { 'invoice.payment.submittedon': JSON.stringify(submittedOn) }),
  };
};

/**
 * Transforms the search prams to the requirements of the export
 */
export const formatExportInvoiceHistoryParams = (searchParams: InvoiceSearchParams) => {
  const { skip, limit, search, ...params } = searchParams;

  if (params.submittedOn) {
    const { gte, lte } = params.submittedOn;
    const { start, end } = limitDateRange([gte, lte], 3);
    params.submittedOn = {
      gte: start,
      lte: end,
    };
  }

  if (params.start && params.end) {
    const { start, end } = limitDateRange([params.start, params.end], 3);
    params.start = start;
    params.end = end;
  }

  // add date ranges if none exists, default is 3 calendar months
  if ((!params.start || !params.end) && !params.submittedOn) {
    // params.start = dayjs().startOf('month').subtract(2, 'month').toISOString();
    params.start = subMonths(startOfMonth(new Date()), 2).toISOString();
    params.end = endOfDay(new Date()).toISOString();
  }

  return params;
};

export const areValidDates = (...dates: (string | Date)[]) =>
  dates.every((dateString) => dateString && !isNaN(new Date(dateString).getTime()));

export const getFilterDisplayDates = (dateRange?: {
  start?: string | Date;
  end?: string | Date;
}): [Date, Date] | [] => {
  let { start, end } = dateRange ?? {};
  if (typeof start === 'string') start = new Date(start);
  if (typeof end === 'string') end = new Date(end);
  if (start && end && areValidDates(start, end)) return [startOfDay(start), endOfDay(end)];
  return [];
};

export const isInvoiceModelV3 = (invoice: InvoiceModel | InvoiceModelV3): invoice is InvoiceModelV3 => {
  return Object.hasOwnProperty.call(invoice, 'personId');
};

export const convertInvoiceV3toInvoiceModel = (
  {
    personId,
    pmId,
    personName,
    personFirstName,
    personLastName,
    personMobileNumber,
    links,
    providerName,
    memo,
    ...invoice
  }: InvoiceModelV3,
  locationId?: string
): InvoiceModel => {
  return {
    ...invoice,
    locationId: locationId || '',
    links: links || {},
    providerName: providerName || '',
    memo: memo || '',
    person: {
      id: personId,
      pmid: pmId || '',
      name: personName || [personFirstName, personLastName].filter(Boolean).join(' '),
      emailAddress: '',
      mobilePhone: personMobileNumber || '',
    },
  };
};
