import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { AddressElement, PaymentElement } from '@stripe/react-stripe-js';
import { StripeAddressElementChangeEvent, StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { useTranslation } from '@frontend/i18n';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { useMultiStepModal } from '@frontend/payments-multistep-modal';
import { theme } from '@frontend/theme';
import { ContentLoader, EmailField, Modal, Text, useControlledField } from '@frontend/design-system';
import { useInvoicePerson, usePaymentModalContext } from '../hooks';
import { useManualCardEntryPayment } from './hooks';

const styles = {
  emailField: css`
    margin-bottom: ${theme.spacing(1.5)};
  `,
  billingAddressTitle: css`
    margin: ${theme.spacing(2, 0)};
  `,
};

const DATA_TRACKING_PREFIX = `pay-client-virtualterminal`;
export const ManualCardEntry = () => {
  const { t } = useTranslation('payments');
  const { goBack } = useMultiStepModal();
  const { invoice } = useSelectedInvoice();
  const { person, personEmail, personFullName, personAddress } = useInvoicePerson(invoice);
  const { onPaymentSuccess } = usePaymentModalContext() ?? {};

  const [emailReceipt, setEmailReceipt] = useState<string | undefined>(personEmail);
  const [cardReadyToSubmit, setCardReadyToSubmit] = useState<boolean>(false);
  const [addressComplete, setAddressComplete] = useState<boolean>(false);
  const [cardName, setCardName] = useState<string | undefined>(undefined);
  const { value: receiptEmail, ...receiptEmailProps } = useControlledField({
    required: false,
    value: emailReceipt,
    type: 'email',
    onChange: (value) => {
      setEmailReceipt(value);
    },
  });

  useEffect(() => {
    if (person) setEmailReceipt(personEmail);
  }, [person]);

  const handleCardChange = (event: StripePaymentElementChangeEvent) => setCardReadyToSubmit(!!event.complete);

  const getCardName = (event: StripeAddressElementChangeEvent) => {
    setAddressComplete(!!event.complete);
    if (event.complete) setCardName(event.value.name);
  };

  const validate = () => !!cardName && !receiptEmailProps.error && cardReadyToSubmit && addressComplete;

  const { makeManualCardEntryPayment, makingManualCardEntryPayment } = useManualCardEntryPayment({
    cardName,
    receiptEmail,
  });

  const onSubmit = async () => {
    if (await makeManualCardEntryPayment()) {
      onPaymentSuccess?.();
      return true;
    }

    return false;
  };

  return (
    <>
      <Modal.Body>
        <ContentLoader show={makingManualCardEntryPayment} message={t('Making Manual Card Entry Payment...')} />
        <form>
          <EmailField
            name='email'
            label={t('Email Receipt (optional)')}
            {...receiptEmailProps}
            actionText={'Clear'}
            data-trackingid={`${DATA_TRACKING_PREFIX}-txt-emailreceipt`}
            onActionClick={emailReceipt ? () => setEmailReceipt('') : undefined}
            hasNonFloatingLabel={true}
            css={styles.emailField}
            value={receiptEmail}
          />
          <PaymentElement onChange={handleCardChange} />
          <Text size='large' weight='bold' as='h3' css={styles.billingAddressTitle}>
            {t('Billing Address')}
          </Text>
          <AddressElement
            onChange={getCardName}
            options={{
              mode: 'billing',
              defaultValues: {
                name: personFullName,
                address: personAddress,
              },
            }}
          />
        </form>
      </Modal.Body>
      <Modal.Footer
        primary={{
          label: t('Complete Payment'),
          onClick: async () => !!(await onSubmit()),
          disabled: !validate() || makingManualCardEntryPayment,
          trackingId: 'pay-portal-collect-btn-manual-card-entry',
        }}
        secondary={{
          label: t('Cancel'),
          onClick: goBack,
          disabled: makingManualCardEntryPayment,
        }}
      />
    </>
  );
};
