import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css({
  display: 'flex',
  gap: theme.spacing(1),
  paddingTop: theme.spacing(1),
  marginLeft: theme.spacing(0.5),
});

export const searchFieldContainerStyle = css({
  flex: 1,
});
