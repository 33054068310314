import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useHasPhoneSettingsAccess } from '@frontend/phone-config';
import { useAppScopeStore } from '@frontend/scope';
import { useFeatureFlagShallowStore } from '@frontend/shared';
import { useShell } from '@frontend/shell-utils';
import { SettingsRoutes } from './settings-routes';
import type { RouteParams } from './types';

type LabelTypeFunction<K extends SettingsRoutes> = (params: RouteParams<K>) => string | Promise<string>;

type DynamicRoutes = {
  [K in SettingsRoutes]: {
    label: LabelTypeFunction<K> | string;
    show?: boolean;
  };
};

export type AllPathsMap = Partial<DynamicRoutes>;

function useSettingsPathLabelMap() {
  const { t } = useTranslation('settingsNav');
  const { getLocationName, selectedLocationIds, accessibleLocationData } = useAppScopeStore();
  const selectedLocationId = selectedLocationIds[0];
  const selectedLocationData = accessibleLocationData[selectedLocationId];
  const selectedLocationParentId = selectedLocationData?.parentId ?? '';
  const { flagValues } = useFeatureFlagShallowStore('flagValues');

  const phoneAccess = useHasPhoneSettingsAccess(selectedLocationIds ?? [], selectedLocationParentId);

  const { isShell, featureAvailability } = useShell();
  const hasShellThemeEnabled = isShell && featureAvailability?.has('shell-theme');

  const hasDomainVerifyAccess = flagValues['domain-verify-settings'];

  const hasTenDLCaccess = flagValues['messaging-compliance-settings'];

  const hasImageGalleryAccess = flagValues['image-gallery-in-settings'];

  const hasMessageQueueAccess =
    selectedLocationIds.filter((locationId) => hasSchemaACL(locationId, Permission.MESSAGE_QUEUE_MANAGE)).length > 0;
  // TODO: BE needs to add the respective permission to the right roles in the meantime before we roll this back out
  // const hasAutoMessagingAccess =
  //   selectedLocationIds.filter((locationId) => hasSchemaACL(locationId, Permission.AUTO_MESSAGING_MANAGE)).length > 0;
  const canViewRecordHistory =
    selectedLocationIds.filter((locationId) => hasSchemaACL(locationId, Permission.VIEW_FULL_PERSON_DATA)).length > 0;

  const pathLabelMap: AllPathsMap = {
    '/organization': { label: t('Organization') },
    '/organization/users': { label: t('Organization Users') },
    '/messaging': { label: t('Messaging') /* AUTO-GENERATED */ },
    '/personal': { label: t('Personal Settings') },
    '/personal/phone/device-connection': {
      label: t('Device Connection') /* AUTO-GENERATED */,
      show: phoneAccess.hasPhonesCustomizationFlag,
    },
    '/tags': { label: t('Tags') /* AUTO-GENERATED */ },
    '/tags/create': { label: t('New Tag') /* AUTO-GENERATED */ },
    '/tags/edit/:tagId': { label: t('Edit Tag') /* AUTO-GENERATED */ },
    '/tags/duplicate/:tagId': {
      label: t('Duplicate Tag') /* AUTO-GENERATED */,
    },
    '/tags/quick-create': { label: t('New Tag') /* AUTO-GENERATED */ },
    '/digital-forms': { label: t('Forms') /* AUTO-GENERATED */ },
    '/payments': { label: t('Payments') /* AUTO-GENERATED */ },
    '/payments/general-settings': {
      label: t('General Settings') /* AUTO-GENERATED */,
    },
    '/payments/online-bill-pay': {
      label: t('Online Bill Pay') /* AUTO-GENERATED */,
    },
    '/payments/payment-methods': {
      label: t('Payment Methods') /* AUTO-GENERATED */,
    },
    '/payments/public-business-information': {
      label: t('Public Business Information') /* AUTO-GENERATED */,
    },
    '/phone': {
      label: t('Phone') /* AUTO-GENERATED */,
    },
    '/phone/audio-library': {
      label: t('Audio Library') /* AUTO-GENERATED */,
      show: phoneAccess.hasAudioLibraryAccess,
    },
    '/phone/call-blocking': {
      label: t('Call Blocking') /* AUTO-GENERATED */,
      show: phoneAccess.hasCallBlockingAccess,
    },
    '/phone/call-groups': {
      label: t('Call Groups') /* AUTO-GENERATED */,
      show: phoneAccess.hasCallGroupsAccess,
    },
    '/phone/call-queues': {
      label: t('Call Queues') /* AUTO-GENERATED */,
      show: phoneAccess.hasCallQueuesAccess,
    },
    '/phone/call-queues/:id': {
      label: t('Call Queue') /* AUTO-GENERATED */,
      show: phoneAccess.hasCallQueuesAccess,
    },
    '/phone/call-recording': {
      label: t('Call Recording') /* AUTO-GENERATED */,
      show: phoneAccess.hasCallRecordingAccess,
    },
    '/phone/call-routes': {
      label: t('Call Routes') /* AUTO-GENERATED */,
      show: phoneAccess.hasCallRoutesAccess,
    },
    '/phone/call-routes/:id': {
      label: t('Call Route') /* AUTO-GENERATED */,
      show: phoneAccess.hasCallRouteDetailsAccess,
    },
    '/phone/contact-directories': {
      label: t('Contact Directories'),
      show: phoneAccess.hasContactDirectoriesAccess,
    },
    '/phone/departments': {
      label: t('Departments') /* AUTO-GENERATED */,
      show: phoneAccess.hasDepartmentsAccess,
    },
    '/phone/departments/:id': {
      label: t('Department') /* AUTO-GENERATED */,
      show: phoneAccess.hasDepartmentsAccess,
    },
    '/phone/devices': {
      label: t('Device Settings') /* AUTO-GENERATED */,
      show: phoneAccess.hasDeviceSettingsAccess,
    },
    '/phone/line-keys': {
      label: t('Line Keys') /* AUTO-GENERATED */,
      show: phoneAccess.hasLineKeysAccess,
    },
    '/phone/mainline': {
      label: t('Mainline') /* AUTO-GENERATED */,
      show: phoneAccess.hasMainLineAccess,
    },
    '/phone/office-hours': {
      label: t('Office-Hours') /* AUTO-GENERATED */,
      show: phoneAccess.hasOfficeHoursAccess,
    },
    '/phone/overrides': {
      label: t('Overrides') /* AUTO-GENERATED */,
      show: phoneAccess.hasOverridesAccess,
    },
    '/phone/phone-tree': {
      label: t('Phone Trees') /* AUTO-GENERATED */,
      show: phoneAccess.hasPhoneTreesAccess,
    },
    '/phone/phone-tree/:id': {
      label: t('Phone Tree') /* AUTO-GENERATED */,
      show: phoneAccess.hasPhoneTreesAccess,
    },
    '/phone/voicemail-box': {
      label: t('Voicemail Boxes') /* AUTO-GENERATED */,
      show: phoneAccess.hasVoicemailBoxesAccess,
    },
    '/phone/voicemail-box/:id': {
      label: t('Voicemail Box') /* AUTO-GENERATED */,
      show: phoneAccess.hasVoicemailBoxesAccess,
    },
    '/phone/hold-music': {
      label: t('Hold Music') /* AUTO-GENERATED */,
      show: phoneAccess.hasHoldMusicAccess,
    },
    '/organization/phone-numbers': {
      label: t('Phone Numbers and Porting') /* AUTO-GENERATED */,
    },
    '/organization/phone-numbers/port-request': {
      label: t('Submit Port Request') /* AUTO-GENERATED */,
    },
    '/messaging/templates': { label: t('Templates') /* AUTO-GENERATED */ },
    '/messaging/templates/create': {
      label: t('New Template') /* AUTO-GENERATED */,
    },
    '/messaging/templates/duplicate/:templateId': {
      label: t('Duplicate Template') /* AUTO-GENERATED */,
    },
    '/messaging/templates/edit/:templateId': {
      label: t('Edit Template') /* AUTO-GENERATED */,
    },
    '/messaging/templates/inbox-edit/:templateId': {
      label: t('Edit Manual Template') /* AUTO-GENERATED */,
    },
    '/messaging/templates/import': {
      label: t('Import Templates') /* AUTO-GENERATED */,
    },
    '/payments/terminals': {
      label: t('General Settings') /* AUTO-GENERATED */,
    },
    '/email': { label: t('Email') /* AUTO-GENERATED */ },
    '/email/manage': { label: t('Manage Email') /* AUTO-GENERATED */ },
    '/fax': { label: t('Fax') /* AUTO-GENERATED */ },
    '/reviews': { label: t('Social') /* AUTO-GENERATED */ },
    '/organization/billing-information': {
      label: t('Billing Information') /* AUTO-GENERATED */,
    },
    '/organization/invoice-history': {
      label: t('Invoice History') /* AUTO-GENERATED */,
    },
    '/organization/single-sign-on': {
      label: t('Single Sign On') /* AUTO-GENERATED */,
    },
    '/organization/payment-methods': {
      label: t('Payment Methods') /* AUTO-GENERATED */,
    },
    '/organization/invoice-history/:locationId': {
      label: (params) => getLocationName(params.locationId) || params.locationId,
    },
    '/personal/diagnostics': {
      label: t('Device & App Diagnostics') /* AUTO-GENERATED */,
    },
    '/messaging/mark-confirmation-as-read': {
      label: t('Mark Confirmed') /* AUTO-GENERATED */,
      show: phoneAccess.hasPhonesCustomizationFlag,
    },
    '/schedule': { label: t('Schedule') /* AUTO-GENERATED */ },
    '/schedule/appointment-types': {
      label: t('Appointment Types') /* AUTO-GENERATED */,
    },
    '/schedule/message-templates': {
      label: t('Message Templates') /* AUTO-GENERATED */,
    },
    '/schedule/office-hours': { label: t('Office Hours') /* AUTO-GENERATED */ },
    '/schedule/message-templates/appointment-confirmed/:locationId': {
      label: t('Appointment Confirmed') /* AUTO-GENERATED */,
    },
    '/schedule/message-templates/request-received/:locationId': {
      label: t('Request Received') /* AUTO-GENERATED */,
    },
    '/schedule/custom-fields': {
      label: t('Custom Fields') /* AUTO-GENERATED */,
    },
    '/personal/customize-app': {
      label: t('Customize Your App') /* AUTO-GENERATED */,
      show: !!hasShellThemeEnabled,
    },
    '/messaging/auto-message-inbox-visibility': {
      label: t('Auto-Message Inbox Visibility') /* AUTO-GENERATED */,
      show: phoneAccess.hasPhonesCustomizationFlag,
    },
    '/subscriptions': {
      label: t('Subscriptions') /* AUTO-GENERATED */,
    },
    '/subscriptions/feature/:id': {
      label: t('Feature Subscriptions') /* AUTO-GENERATED */,
    },
    '/subscriptions/manage-subscription': {
      label: t('Manage Subscription') /* AUTO-GENERATED */,
    },
    '/schedule/appointment-types/:locationId': {
      label: (params) => getLocationName(params.locationId) || params.locationId,
    },
    '/schedule/manage-providers/:locationId': {
      label: (params) => getLocationName(params.locationId) || params.locationId,
    },
    '/schedule/manage-providers': {
      label: t('Manage Providers') /* AUTO-GENERATED */,
    },
    '/insurance': {
      label: t('Insurance'),
    },
    '/insurance/automatic-verification': {
      label: t('Automatic Verification'),
    },
    '/insurance/automatic-verification/:locationId': {
      label: (params) => getLocationName(params.locationId) || params.locationId,
    },

    '/personal/notifications/:type': {
      label: (params) => {
        const map: Record<string, string> = {
          'chat-message-new': t('Chat'),
          'fax-new': t('Fax'),
          'form-submission-new': t('Forms'),
          'review-new': t('Reviews'),
          'sms-message-new': t('SMS Messages'),
          payments: t('Payments'),
          'sms-tag': t('Tags'),
          'schedule-request-new': t('Schedule Request'),
          'incoming-call': t('Call Pop'),
        };
        return map[params.type];
      },
    },
    '/personal/notifications/position-and-dismissal': {
      label: t('Position and Dismissal') /* AUTO-GENERATED */,
    },
    '/messaging/show-unreplied-message-status': {
      label: t('Unreplied Message Status Visiblity') /* AUTO-GENERATED */,
    },
    '/personal/preferences/accessibility': {
      label: t('Accessibility') /* AUTO-GENERATED */,
    },
    '/email/domain-verify': {
      label: t('Domain Verification') /* AUTO-GENERATED */,
      show: hasDomainVerifyAccess,
    },
    '/schedule/treatment-rooms': {
      label: t('Treatment Rooms') /* AUTO-GENERATED */,
    },
    '/schedule/treatment-rooms/:locationId': {
      label: (params) => getLocationName(params.locationId) || params.locationId,
    },
    '/payments/account-info': {
      label: t('Account Info and Status') /* AUTO-GENERATED */,
    },
    '/messaging/auto-messages': {
      label: t('Auto-Messages') /* AUTO-GENERATED */,
      // show: hasAutoMessagingAccess,
    },
    '/messaging/auto-messages/:ruleId': {
      label: t('Auto-Messages Rule') /* AUTO-GENERATED */,
      // show: hasAutoMessagingAccess,
    },
    '/image-gallery': {
      label: t('Image Gallery') /* AUTO-GENERATED */,
      show: hasImageGalleryAccess,
    },
    '/contacts': { label: t('Contacts') /* AUTO-GENERATED */ },
    '/contacts/manage-csv': { label: t('Manage CSVs') /* AUTO-GENERATED */ },
    '/contacts/file-mapping': { label: t('File Mapping') /* AUTO-GENERATED */ },
    '/organization/brand': { label: t('Brand Details') /* AUTO-GENERATED */ },
    '/contacts/review-upload': {
      label: t('Review Your Upload') /* AUTO-GENERATED */,
    },
    '/schedule/treatment-rooms/edit/:operatoryId': {
      label: t('Edit Treatment Room') /* AUTO-GENERATED */,
    },
    '/schedule/treatment-rooms/:locationId/edit/:operatoryId': {
      label: t('Edit Treatment Room') /* AUTO-GENERATED */,
    },
    '/schedule/manage-providers/edit/:providerId': {
      label: t('Edit Provider') /* AUTO-GENERATED */,
    },
    '/schedule/manage-providers/:locationId/edit/:providerId': {
      label: t('Edit Provider') /* AUTO-GENERATED */,
    },
    '/schedule/booking-site-setup': {
      label: t('Booking Site Setup') /* AUTO-GENERATED */,
    },
    '/organization/messaging-compliance-registration': {
      label: t('Messaging Compliance Registration') /* AUTO-GENERATED */,
      show: hasTenDLCaccess,
    },
    '/payments/justifi-settings': {
      label: t('Justifi Settings') /* AUTO-GENERATED */,
    },
    '/organization/business-verification': {
      label: t('Business Verification') /* AUTO-GENERATED */,
    },
    '/messaging/message-queue': {
      label: t('Message Queue') /* AUTO-GENERATED */,
      show: hasMessageQueueAccess,
    },
    '/messaging/text-connect/:locationId': {
      label: (params) => getLocationName(params.locationId) || params.locationId,
    },
    '/fax/history': { label: t('Fax History') /* AUTO-GENERATED */ },
    '/messaging/record-history': {
      label: t('Record History') /* AUTO-GENERATED */,
      show: canViewRecordHistory,
    },
    '/messaging/record-history/:type/:recordId': {
      label: t('Record Details') /* AUTO-GENERATED */,
      show: canViewRecordHistory,
    },
    '/email/gallery': { label: t('Gallery') /* AUTO-GENERATED */ },
    '/messaging/text-connect': {
      label: t('Text Connect Settings') /* AUTO-GENERATED */,
    },
    '/email/campaigns': { label: t('Campaigns') /* AUTO-GENERATED */ },
    '/email/filters': { label: t('Filters') /* AUTO-GENERATED */ },
    '/email/settings': { label: t('Settings') /* AUTO-GENERATED */ },
    '/email/templates': { label: t('Templates') /* AUTO-GENERATED */ },
    '/email/campaign/:id': { label: t('Edit Campaign') /* AUTO-GENERATED */ },
    '/email/campaigns/:type': {
      label: t('Edit Campaign') /* AUTO-GENERATED */,
    },
    '/email/filter/:filterId': { label: t('Filter') /* AUTO-GENERATED */ },
    '/email/templates/:templateId': {
      label: t('Template') /* AUTO-GENERATED */,
    },
    '/email/campaign/:id/send': {
      label: t('Edit Campaign Send') /* AUTO-GENERATED */,
    },
    '/email/campaign/:id/success': {
      label: t('Edit Campaign Success') /* AUTO-GENERATED */,
    },
    '/organization/data-sync': {
      label: t('Data Sync - Choose Location') /* AUTO-GENERATED */,
    },
    '/organization/csv-upload': {
      label: t('Imported Contact Lists') /* AUTO-GENERATED */,
    },
    '/organization/csv-upload/map': {
      label: t('CSV Mapping') /* AUTO-GENERATED */,
    },
    '/organization/csv-upload/preview': {
      label: t('CSV Preview') /* AUTO-GENERATED */,
    },
    '/organization/data-sync/location/:locationId': {
      label: t('Data Sync') /* AUTO-GENERATED */,
    },
    '/organization/data-sync/location/:locationId/source/:sourceId': {
      label: t('Customize Integration') /* AUTO-GENERATED */,
    },
    '/organization/data-sync/location/:locationId/new': {
      label: t('Add New Integration') /* AUTO-GENERATED */,
    },
  };

  return pathLabelMap;
}

export { useSettingsPathLabelMap };
