import { createContext, useContext } from 'react';

export type UsePaymentModal = {
  onPaymentSuccess?: () => void;
  onInvoiceCreated?: () => void;
};

const PaymentModalContext = createContext<UsePaymentModal | undefined>(undefined);

/**
 * Context hook with callbacks intended for collection steps.
 * Only works if <PaymentModalProvider /> context has been implemented.
 *
 * Currently only implemented in `usePaymentAction() => ({Modal: PaymentsModal})`
 *
 * Note: Not using contextFactory because it throws an error when context is not set.
 * This is an optional context that needs to be backward compatible.
 */
export const usePaymentModalContext = () => {
  return useContext(PaymentModalContext);
};

export const PaymentModalProvider = ({
  onPaymentSuccess,
  onInvoiceCreated,
  children,
}: React.PropsWithChildren<UsePaymentModal>) => {
  return (
    <PaymentModalContext.Provider value={{ onPaymentSuccess, onInvoiceCreated }}>
      {children}
    </PaymentModalContext.Provider>
  );
};
