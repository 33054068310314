import dayjs from 'dayjs';
import { SchemaIO } from '@frontend/schema';
import { ServiceInfiniteQueries } from '../types';
import { UseSMSDataV3InfiniteQueryEndpointArgs, useSMSDataV3InfiniteQuery } from './use-sms-data-v3-infinite-query';

type ListThreadsPageParam = {
  lastCreatedAt: string;
  lastThreadId: string;
};
type ListThreadsIO = SchemaIO<ServiceInfiniteQueries['ListThreads']>;
/**
 * A hook that returns a query for the `ListThreads` query endpoint.
 * @param request The request object to pass to the query. Overriding pagination fields on the request is handled by the hook.
 * @param options (optional) The options to pass to `useInfiniteQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param requestKeysToOmitFromQueryKey (optional) - An array of keys to omit from the query key. This is useful for cases where the request object contains keys that cause issues
 * when included in the query key, such as the current timestamp.
 */
export const useListThreadsQuery = <E = unknown, D = ListThreadsIO['output']>({
  request,
  options,
  requestKeysToOmitFromQueryKey = [],
  ...args
}: UseSMSDataV3InfiniteQueryEndpointArgs<
  'ListThreads',
  E,
  D,
  ListThreadsPageParam,
  Omit<ListThreadsIO['input'], 'start' | 'end' | 'threadSkip' | 'messageSkip' | 'tagIds'>
>) => {
  // Omit fetchPreviousPage from the query object to prevent accidental use instead of fetchNextPage
  const { fetchPreviousPage: _, ...query } = useSMSDataV3InfiniteQuery<'ListThreads', E, D, ListThreadsPageParam>({
    endpointName: 'ListThreads',
    ...args,
    request: ({ pageParam }) => {
      const result: ListThreadsIO['input'] = {
        // Default lastCreatedAt to 5 seconds in the future to ensure we get the latest data.
        lastCreatedAt: dayjs().add(5, 'second').toISOString(),
        tagsEnabled: true,
        ...request,
      };
      if (pageParam?.lastCreatedAt) result.lastCreatedAt = pageParam.lastCreatedAt;
      if (pageParam?.lastThreadId) result.lastThreadId = pageParam.lastThreadId;
      return result;
    },
    options: {
      ...options,
      getNextPageParam: (oldestPage) => {
        const hasLastPage = oldestPage.threads.length === 0;
        const lastThread = oldestPage.threads.at(-1);
        const lastSms = lastThread?.messages.at(-1);
        if (hasLastPage || !lastThread || !lastSms) return undefined;

        return {
          lastThreadId: lastThread.id,
          lastCreatedAt: lastSms.createdAt,
        };
      },
    },
    requestKeysToOmitFromQueryKey: Array.from(new Set(requestKeysToOmitFromQueryKey.concat(['lastCreatedAt']))),
  });

  return query;
};
