import { VerifyAuthRequest } from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import { UseQueryOptions, useQueries, useQuery } from 'react-query';
import { useLocalizedQuery, Location } from '@frontend/location-helpers';
import { HTTPBoundSchemaMethod, SchemaIO } from '@frontend/schema';
import { useScopedQuery } from '@frontend/scope';
import { Unpromise } from '@frontend/types';
import { UsersTypes } from '@frontend/user-helpers';
import { getAuthVeify, getUserDetails, getUserPermissions, getUsersInLocation, list, SchemaAuthService } from './api';

type SchemaQueryOptions<T extends HTTPBoundSchemaMethod<any>> = UseQueryOptions<
  SchemaIO<T>['output'],
  unknown,
  SchemaIO<T>['output']
>;

type GetLocationUserQueryParams = {
  locationId: string;
  locationType: 'multi' | 'single';
  locationData: Location | null;
  profileId?: string;
};
type GetInviteUserQueryParams = {
  email: string;
  locationId: string;
  locationType: 'multi' | 'single';
  locationData: Location | null;
};
type GetUsersQueryParams = {
  showChildren?: boolean;
};
type GetCreateUserQueryParams = {
  nonceToken: string;
  locationId: string;
};
type GetPasswordPoliciesQueryParams = {
  queryEnabled: boolean;
  locationId: string;
};

export const queryKeys = {
  base: ['users'] as const,
  profile: () => [...queryKeys.base, 'profile'],
  passwordPolicy: () => [...queryKeys.base, 'passwordPolicy'],
  users: () => [...queryKeys.base, `usersList`],
  inviteUserData: (locationId: string) => [...queryKeys.base, `invite-${locationId}`],
  editUserData: (userId: string, locationId: string) => [...queryKeys.base, `edit-${userId}-${locationId}`],
  userDetails: (userId: string) => [...queryKeys.base, `user-details-${userId}`],
  userPermissions: () => [...queryKeys.base, `user-permissions`],
  usersInLocations: (locationIds: string[]) => [...queryKeys.base, 'user-in-locations', locationIds],
};

export const useGetUsers = (parameters: {
  params?: GetUsersQueryParams;
  opts?: UseQueryOptions<Unpromise<ReturnType<typeof list.users>>>;
}) => {
  return useLocalizedQuery({
    cacheTime: 0,
    queryKey: queryKeys.users(),
    queryFn: () => list.users(parameters.params?.showChildren),
    ...parameters.opts,
  });
};

export const useGetMultiLocationsUsers = ({
  locationIds,
  ...parameters
}: {
  locationIds: string[];
  params?: GetUsersQueryParams;
  opts?: UseQueryOptions<UsersTypes.User[]>;
}) => {
  const { showChildren } = parameters.params || {};

  return useQueries(
    locationIds.map((locationId) => ({
      queryKey: [locationId, ...queryKeys.users()],
      queryFn: () => list.users(showChildren, locationId),
      ...parameters.opts,
    }))
  );
};

export const useGetLocationUser = (params: GetLocationUserQueryParams) => {
  return useLocalizedQuery({
    retry: 1,
    cacheTime: 0,
    enabled: !!params.profileId && !!params.locationData,
    queryKey: queryKeys.editUserData(params.profileId!, params.locationId),
    queryFn: () =>
      list.userLocationEditData({
        location: params.locationData!,
        profileId: params.profileId!,
        locationType: params.locationType,
      }),
  });
};

export const useGetInviteUserData = (params: GetInviteUserQueryParams) => {
  return useLocalizedQuery({
    retry: 1,
    enabled: !!params.email.length && !!params.locationData,
    queryKey: queryKeys.inviteUserData(params.locationId),
    queryFn: async () => {
      const profile = await list.userSearch(params.email as string);
      return list.userLocationInviteData({
        location: params.locationData!,
        profile,
        locationType: params.locationType,
      });
    },
  });
};

export const useGetCreateUserData = (params: GetCreateUserQueryParams, options: { enabled: boolean }) => {
  return useQuery({
    retry: 0,
    queryKey: queryKeys.profile(),
    enabled: !!params.nonceToken && !!params.locationId && options.enabled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    queryFn: () => list.userNonce({ locationId: params.locationId, nonceToken: params.nonceToken }),
  });
};

export const useGetPasswordPolicies = (params: GetPasswordPoliciesQueryParams) => {
  return useLocalizedQuery({
    enabled: params.queryEnabled,
    queryKey: queryKeys.passwordPolicy(),
    queryFn: () => list.passwordPolicies({ locationId: params.locationId }),
  });
};

export const useGetUserDetails = (userId: string) => {
  return useScopedQuery({
    enabled: !!userId,
    queryKey: queryKeys.userDetails(userId),
    queryFn: () => getUserDetails(userId),
  });
};

export const useGetUserPermissions = () => {
  return useScopedQuery({
    queryKey: queryKeys.userPermissions(),
    queryFn: () => getUserPermissions(),
  });
};

//fetch list of users in selected locations
export const useGetUsersInLocations = (locationIds: string[]) => {
  return useScopedQuery({
    queryKey: queryKeys.usersInLocations(locationIds),
    queryFn: () => {
      return getUsersInLocation(locationIds);
    },
  });
};

//fetch list of users in selected locations
export const useGetVerifyAuth = (payload: VerifyAuthRequest) => {
  return useScopedQuery({
    queryKey: 'verify-auth',
    queryFn: () => getAuthVeify(payload),
  });
};

export const useGetContacts = (
  userId: string,
  opts: SchemaQueryOptions<(typeof SchemaAuthService)['GetContacts']> = {}
) =>
  useQuery({
    queryKey: 'get-contacts',
    queryFn: () => SchemaAuthService.GetContacts({ userId }),
    ...opts,
  });
