import {
  DateTypeFilter,
  FilterPaymentType,
  getFilterDisplayDates,
  InvoiceFilterStatus,
  InvoiceFilterType,
  InvoiceSearchParams,
  PaymentType,
  RecordedValue,
} from '@frontend/api-invoices';

export const defaultInvoicesStatusFilter = [
  InvoiceFilterStatus.paid,
  InvoiceFilterStatus.unpaid,
  InvoiceFilterStatus.partiallyPaid,
];

/**
 * Gets the search params to use in the invoice request from the invoice filter
 * @param filter Invoice filter
 */
export const getSearchParams = (filter: InvoiceFilterType, locationIds: string[]): InvoiceSearchParams => {
  const params: InvoiceSearchParams = {};

  if (filter.recorded) {
    params.isRecorded = filter?.recorded === RecordedValue.recorded;
  }

  if (filter.person) {
    const personText = filter.person.toLowerCase();
    if (personText.startsWith('id:')) {
      params.personid = personText.replace(/^id:/, '');
    } else {
      params.search = filter.person;
    }
  }

  if (filter.status) {
    params.status = !!filter.status?.length ? filter.status : defaultInvoicesStatusFilter;
  }
  if (filter.paymentType) {
    params['invoice.payment.origin'] = filter.paymentType;
  }
  if (filter.cardType) {
    params['invoice.payment.cardtype'] = filter.cardType;
  }
  if (filter.paymentMethod) {
    const paymentMethods = [
      ...filter.paymentMethod,
      ...(filter.paymentMethod.includes(FilterPaymentType.Card) ? [PaymentType.CardPresent] : []),
    ];
    params['invoice.payment.paymenttype'] = paymentMethods as PaymentType[];
  }
  if (locationIds.length) {
    params.locationIds = locationIds;
  }
  if (filter.providerName) {
    params.providername = [filter.providerName];
  }

  const [startDate, endDate] = getFilterDisplayDates(filter.dateRange);

  const hasDateCreatedSelected = filter?.dateType === DateTypeFilter.Created;
  const hasInvoiceStatusSelected = !!params.status?.length;

  if (filter.dateType === DateTypeFilter.Paid) {
    params.submittedOn = {
      gte: startDate?.toISOString(),
      lte: endDate?.toISOString(),
    };
    params.order = '-paidAt';
  } else if ((hasDateCreatedSelected || hasInvoiceStatusSelected) && startDate && endDate) {
    params.start = startDate?.toISOString();
    params.end = endDate?.toISOString();
  } else if (startDate && endDate) {
    // if there is no filter set, startDate and endDate
    // will be null. So this will be the default when no
    // filter is set.
    params.start = startDate?.toISOString();
    params.end = endDate?.toISOString();
  }

  return params;
};
