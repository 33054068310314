import { HTMLAttributes } from 'react';
import { Node, Text as SlateText } from 'slate';
import urlRegex from 'url-regex';
import { useStyles } from '../../../use-styles';
import { Text } from '../../text';
import { useTemplateEditorValue, useTemplateSettings } from '../message-template.context';
import { isTagNode } from '../message-template.models';

const lengthWithShortUrls = (text: string, shortenUrlsTo?: string) => {
  return shortenUrlsTo ? text.replaceAll(urlRegex(), shortenUrlsTo).length : text.length;
};

const getNodeCharCount = (node: Node, shortenUrlsTo?: string) => {
  let count = 0;

  if (SlateText.isText(node)) {
    count += lengthWithShortUrls(Node.string(node), shortenUrlsTo);
  }

  if (isTagNode(node) && !node.tag.invalid) {
    count += lengthWithShortUrls(node.tag.value, shortenUrlsTo);
  }

  if (node.children) {
    const children = node.children as Node[];
    count += children.reduce((childCount, child) => (childCount += getNodeCharCount(child, shortenUrlsTo)), 0);
  }

  return count;
};

const getCharCount = (lines: Node[], shortenUrlsTo?: string) => {
  // each line has a carriage return, which counts as two characters
  // so add an additional 2 to the character count for each line
  const count = lines.reduce((count, line) => count + getNodeCharCount(line, shortenUrlsTo) + 2, 0);
  return count - 2; // remove 2 character counts as there are n - 1 carriage returns for n lines
};

type TemplateCharCountProps = HTMLAttributes<HTMLParagraphElement> & {
  limit?: number;
};

export const TemplateCharCount = ({ limit }: TemplateCharCountProps) => {
  const templateCharCountStyles = useStyles('MessageTemplate', 'templateCharCount');
  const nodes = useTemplateEditorValue();
  const { shortenUrlsTo } = useTemplateSettings();

  return (
    <div className='message-template-char-count' css={templateCharCountStyles}>
      <Text size='large' weight='bold' as='span'>
        {getCharCount(nodes, shortenUrlsTo)}
        {Number.isInteger(limit) && ` / ${limit}`}
      </Text>
      <Text as='span' size='small'>
        {' '}
        characters
      </Text>
    </div>
  );
};
