import { useQuery } from 'react-query';
import { LocationsApi } from '@frontend/api-locations';
import type { ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';

type Props = {
  parentId: string | null;
  locationId: string;
};

export const useMultiLocationCheck = (
  { parentId, locationId }: Props,
  options: ContextlessQueryObserverOptions = {}
) => {
  const { data: children } = useQuery({
    queryKey: ['multi-location-check', parentId, locationId],
    queryFn: () => LocationsApi.getChildrenLocations(locationId),
    ...options,
    enabled: !parentId && options.enabled,
  });

  const isMultiLocation = !!parentId || (Array.isArray(children) && children.length > 0);
  const isParentLocation = !parentId && isMultiLocation;
  const childLocations = children ?? [];

  return { childLocations, isMultiLocation, isParentLocation };
};
