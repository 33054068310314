import { QueryKey, UseQueryOptions, useQueryClient } from 'react-query';
import { useDevToolsStore } from '@frontend/devtools';
import { useQuery } from '@frontend/react-query-helpers';
import { useLocalizedQueryScoper } from './legacy-location-scoper';
import { useLocationDataShallowStore } from './use-location-data';

/**
 * This hook is a wrapper around useQuery with the option object signature.
 *
 * This wrapper simply prepends the query keys with the current `locationId` provided by the useLocationData hook.
 * The `locationId` is available to the `queryFn` as the first element in the `queryKey` array.
 */
/**
 * @deprecated This component is deprecated and will be removed as part of the Murder The Portal initiative.
 * This is due to the `useLocationDataShallowStore` also being deprecated.
 * Please use `useScopedQuery` instead.
 */
export function useLocalizedQuery<TQueryFnData, TError, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  arg: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
) {
  const scoper = useLocalizedQueryScoper();
  const locationIdFromStore = useLocationDataShallowStore('locationId')?.locationId;
  const locationId = scoper?.locationId ?? locationIdFromStore;
  const { queryKey, queryFn } = arg;

  let key: string[] = [];
  if (typeof queryKey === 'string') {
    key = [queryKey];
  } else if (Array.isArray(queryKey)) {
    key = queryKey;
  }

  const localizedQueryKey: QueryKey = [locationId, ...key];
  const modifiedArguments = {
    enabled: !!locationId,
    ...arg,
    queryKey: localizedQueryKey,
    queryFn: scoper
      ? async (...args) => {
          scoper?.scope();
          let res;
          try {
            res = await queryFn?.(...args);
          } finally {
            scoper?.unscope();
          }
          return res;
        }
      : queryFn,
  } as typeof arg;

  const {
    options: { debugUseLocalizedQuery },
  } = useDevToolsStore();

  if (debugUseLocalizedQuery) {
    console.info('%cQuery params:', 'color: aqua', modifiedArguments);
  }

  return useQuery(modifiedArguments);
}

export function useLocalizedFreshQuery<
  TQueryFnData,
  TError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(arg: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>) {
  const scoper = useLocalizedQueryScoper();
  const locationIdFromStore = useLocationDataShallowStore('locationId')?.locationId;
  const locationId = scoper?.locationId ?? locationIdFromStore;
  const { queryKey } = arg;

  let key: string[] = [];
  if (typeof queryKey === 'string') {
    key = [queryKey];
  } else if (Array.isArray(queryKey)) {
    key = queryKey;
  }

  const localizedQueryKey: QueryKey = [locationId, ...key];
  const queryFn = arg.queryFn;

  const modifiedArguments = {
    enabled: !!locationId,
    ...arg,
    queryKey: localizedQueryKey,
    staleTime: 0,
    queryFn: scoper
      ? async (...args) => {
          scoper?.scope();
          let res;
          try {
            res = await queryFn?.(...args);
          } finally {
            scoper?.unscope();
          }
          return res;
        }
      : queryFn,
  } as typeof arg;

  const {
    options: { debugUseLocalizedQuery },
  } = useDevToolsStore();

  if (debugUseLocalizedQuery) {
    console.info('%cQuery params:', 'color: aqua', modifiedArguments);
  }

  return useQuery(modifiedArguments);
}

export function useInvalidateQueries() {
  const qc = useQueryClient();

  return (queryKey: QueryKey) =>
    qc.invalidateQueries({
      predicate: (query) => {
        if (typeof queryKey === 'string') {
          return query.queryKey.includes(queryKey);
        } else if (Array.isArray(queryKey)) {
          const [_locationId, ...rest] = query.queryKey;

          return rest.every((key, index) => queryKey[index] === key);
        }
        return false;
      },
    });
}
