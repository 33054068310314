import { SendFormStep } from '../types';

const FORMS_ORIGIN_STEPS = [
  SendFormStep.SELECT_CONTACT,
  SendFormStep.SELECT_APPOINTMENT,
  SendFormStep.SELECT_DELIVERY_METHOD,
  SendFormStep.SELECTED_MESSAGE_MODE,
];

const CONTACTS_ORIGIN_STEPS = [
  SendFormStep.SELECT_DOCUMENT,
  SendFormStep.SELECT_APPOINTMENT,
  SendFormStep.SELECT_DELIVERY_METHOD,
  SendFormStep.SELECTED_MESSAGE_MODE,
];

const FORMS_COMPLETE_STEPS = [
  SendFormStep.SELECT_CONTACT,
  SendFormStep.SELECT_DOCUMENT,
  SendFormStep.SELECT_APPOINTMENT,
  SendFormStep.SELECT_DELIVERY_METHOD,
  SendFormStep.SELECTED_MESSAGE_MODE,
];

export const SEND_FORM_STEPS = {
  formsOrigin: FORMS_ORIGIN_STEPS,
  formsOriginWithMultipleLocations: [SendFormStep.SELECT_LOCATION, ...FORMS_ORIGIN_STEPS],
  contactsOrigin: CONTACTS_ORIGIN_STEPS,
  contactsOriginWithMultipleLocations: [SendFormStep.SELECT_LOCATION, ...CONTACTS_ORIGIN_STEPS],
  completeOrigin: FORMS_COMPLETE_STEPS,
  completeOriginWithMultipleLocations: [SendFormStep.SELECT_LOCATION, ...FORMS_COMPLETE_STEPS],
};
