export type PaymentStatus = 'STATUS_UNKNOWN' | 'FAILED' | 'REFUNDED' | 'PARTIALLY_REFUNDED' | 'PENDING' | 'SUCCEEDED';

export type StatusKeys = 'paid' | 'partiallyPaid' | 'unpaid';

export enum PaymentOrigin {
  ManualCardEntry = 'LOCATION',
  OnlineBillPay = 'LOCATION_PORTAL',
  TextToPay = 'PAYMENT_PORTAL',
  Terminal = 'TERMINAL',
  MobileTapToPay = 'MOBILE_TAP_TO_PAY',
  OnlineScheduling = 'ONLINE_SCHEDULING',
  PaymentPlan = 'PAYMENT_PLAN',
}

export enum FilterPaymentType {
  Card = 'CARD',
  Affirm = 'AFFIRM',
  Link = 'LINK',
  ACH = 'ACH',
}

export enum PaymentType {
  CardPresent = 'CARD_PRESENT',
  Card = 'CARD',
  Affirm = 'AFFIRM',
  Link = 'LINK',
  ACH = 'ACH',
}

export type FilterPaymentTypeKeys = keyof typeof FilterPaymentType;

export interface PaymentModel {
  confirmationCode: string;
  fee: number;
  hasRefund: boolean;
  net: number;
  origin: PaymentOrigin;
  paidAmount: number;
  paidAt: string;
  paymentDetails: {
    lastFour: string;
    brand: CreditCardBrand;
    cardholderName: string;
    bankAccountHolderName: string;
    method: unknown;
    funding: unknown;
  };
  paymentId: string;
  paymentType: PaymentType;
  recordedAt?: string;
  receiptEmail: string;
  refunds?: PaymentRefund[];
  status?: PaymentStatus;
  statusReason?: string;
}

export type PaymentRefund = {
  amount: number;
  createdAt: string;
  failureReason: string;
  id: string;
  paymentId: string;
  refundedBy: string;
  status: 'PENDING' | 'SUCCEEDED' | 'FAILED';
  stripeId: string;
};

export enum InvoiceStatus {
  StatusUnknown = 'STATUS_UNKNOWN',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Locked = 'LOCKED',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  Unpaid = 'UNPAID',
}

export enum CreditCardBrand {
  Amex = 'AMEX',
  Diners = 'DINERS',
  Discover = 'DISCOVER',
  JCB = 'JCB',
  MasterCard = 'MASTERCARD',
  UnionPay = 'UNIONPAY',
  Visa = 'VISA',
  CardBrandUnknown = 'CARD_BRAND_UNKNOWN',
}

export type CreditCardBrandKeys = keyof typeof CreditCardBrand;

export interface InvoiceModel {
  id: string;
  billedAmount: number;
  billedAt: string;
  hasAttachment: boolean;
  isActive: boolean;
  links: {
    attachment?: string;
    payment?: string;
    paymentPortal?: string;
    self?: string;
  };
  payment?: PaymentModel;
  payments?: PaymentModel[];
  person: {
    id: string;
    pmid: string;
    name: string;
    emailAddress: string;
    mobilePhone: string;
  };
  status: InvoiceStatus;
  uniqueLink: string;
  locationId: string;
  providerName?: string;
  memo?: string;
}

/**
 * Might be unique to v3 create invoice response
 */
export interface InvoiceModelV3 {
  billedAmount: number;
  billedAt: string;
  hasAttachment: boolean;
  id: string;
  isActive: boolean;
  links: {
    attachment?: string;
    payment?: string;
    paymentPortal?: string;
    self?: string;
  } | null;
  memo: string | null;
  personFirstName: string | null;
  personId: string;
  personLastName: string | null;
  personMobileNumber: string | null;
  personName: string | null;
  pmId: string | null;
  providerName: string | null;
  skipAttachmentAuth: boolean;
  status: InvoiceStatus;
  uniqueLink: string;
}

export type InvoiceDetailModel = InvoiceModel & { attachments?: { filename: string }[] };

export type InvoiceSummary = {
  gross: number;
  fees: number;
  net: number;
  invoicesCount: number;
  refunds?: number;
};

export interface InvoiceSearchParams {
  active?: boolean;
  end?: string;
  isRecorded?: boolean;
  limit?: number;
  order?:
    | '-billedAt'
    | 'billedAt'
    | '-billedAmount'
    | 'billedAmount'
    | '-paidAt'
    | 'paidAt'
    | '-paidAmount'
    | 'paidAmount'
    | '-fee'
    | 'fee'
    | '-net'
    | 'net';
  personid?: string;
  search?: string;
  skip?: number;
  start?: string;
  status?: string[];
  submittedOn?: {
    gte?: string;
    lte?: string;
  };
  'invoice.payment.origin'?: PaymentOrigin[];
  'invoice.payment.cardtype'?: CreditCardBrand[];
  'invoice.payment.paymenttype'?: PaymentType[];
  locationIds?: (string | undefined)[];
  providername?: string[];
}

export type InvoiceHistoryResponse = {
  invoices: InvoiceModel[];
  summary: InvoiceSummary | undefined;
};

export type InvoiceResponse = {
  data?: InvoiceHistoryResponse;
  meta?: { links: { next?: string; previous?: string } };
};

export type PaymentRequestPayload = {
  personId: string;
  amount: number;
  mobileNumber: string | undefined;
  attachment?: File;
  skipAttachmentAuth?: boolean;
  providerName?: string;
  memo?: string;
};

export enum InvoiceRefundStatus {
  Refunded = 'Refunded',
  PartialRefund = 'Partial Refund',
}

export enum DateTypeFilter {
  Created = 'Created',
  Paid = 'Paid',
}

export enum InvoiceFilterStatus {
  paid = 'PAID',
  partiallyPaid = 'PARTIALLY_PAID',
  unpaid = 'UNPAID',
  canceled = 'CANCELED',
}

export enum RecordedValue {
  recorded = 'recorded',
  notRecorded = 'not_recorded',
}

export type InvoiceFilterType = {
  person?: string;
  dateType?: DateTypeFilter;
  dateRange?: {
    start: string | undefined;
    end: string | undefined;
  };
  status?: (InvoiceFilterStatus | InvoiceRefundStatus)[];
  recorded?: RecordedValue;
  paymentType?: PaymentOrigin[];
  paymentMethod?: FilterPaymentType[];
  cardType?: CreditCardBrand[];
  providerLocation?: string;
  providerName?: string;
};
