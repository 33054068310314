import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';
import { NotificationDoc } from '@weave/schema-gen-ts/dist/schemas/notification/v1/notification_api.pb';
import {
  onSnapshot,
  collection,
  query,
  where,
  orderBy,
  limit,
  QueryDocumentSnapshot,
  Timestamp,
  writeBatch,
  updateDoc,
  getDoc,
  doc,
} from 'firebase/firestore';
import mitt from 'mitt';
import { getDecodedWeaveToken } from '@frontend/auth-helpers';
import { useLocationDataStore } from '@frontend/location-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { useMultiLocationFeature } from '@frontend/shared';
import { IPCRendererCallback, useShell } from '@frontend/shell-utils';
import { sentry } from '@frontend/tracking';
import {
  GetWeavePopNotificationByType,
  GetWeavePopNotificationActionsByType,
  WeavePopNotification,
} from '@frontend/types';
import { notificationSounds as soundOptions } from './audio/notification-sounds';
import { useFirestoreDbQuery } from './firestore';
import { TransientQueueProvider } from './transient-queue';
import { useNotificationFiltersStore } from './use-notification-filters';

const emitter = mitt<EmitterEvents>();

type EmitterEvents = {
  [P in WeavePopNotification['type']]: {
    notification: GetWeavePopNotificationByType<P>;
    action: GetWeavePopNotificationActionsByType<P>['action'];
    payload: GetWeavePopNotificationActionsByType<P>['payload'];
  };
};

type ContextValue = {
  emitter: typeof emitter;
  /** this is used for emitting notification actions from non-shell contexts */
  emit: (typeof emitter)['emit'];
  firebaseError: Error | null;
  notifications: NotificationDoc[] | null;
  isLoading: boolean;
  unreadCount: null | number;
  maxLocationsSelected: boolean;
  notificationTrayIsOpen: boolean;
  setNotificationTrayIsOpen: Dispatch<SetStateAction<boolean>>;
  markThreadNotificationsAsRead: (threadId: string, locationId: string) => void;
  toggleNotificationAsRead: (notificationId: string, hasRead: boolean) => void;
} & ReturnType<typeof useShellPingPong>;

const NotificationContext = createContext<ContextValue>({
  emitter,
  emit: emitter.emit,
  notifications: [],
  firebaseError: null,
  isLoading: true,
  unreadCount: null,
  maxLocationsSelected: false,
  status: 'idle',
  replay: () => {},
  latency: undefined,
  error: undefined,
  markThreadNotificationsAsRead: () => {},
  toggleNotificationAsRead: () => {},
  notificationTrayIsOpen: false,
  setNotificationTrayIsOpen: () => {},
});

export const converter = {
  toFirestore: (data: NotificationDoc) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => snapshot.data() as NotificationDoc,
};

/**
 * firestore has a limit of 30 `in` operators on the same field. we decided that we probably
 * wouldn't want to allow users to see notifications from more than 30 locations at a time anyway.
 * there is plenty of ui to help the user know that they are selecting too many locations.
 */
const MAX_SELECTABLE_LOCATIONS = 30;

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  // getting this user id from the token because...
  const userId = getDecodedWeaveToken()?.user_id ?? '';
  // firebase doesn't have a loading state, so use null to track loading
  const firestoreDbQuery = useFirestoreDbQuery();
  const [data, setData] = useState<NotificationDoc[] | null>(null);
  const [unreadCount, setUnreadCount] = useState<null | number>(null);
  const { selectedLocationIds: multiSelectedLocationIds } = useAppScopeStore();
  const { locationId } = useLocationDataStore();
  const [firebaseError, setFirebaseError] = useState<Error | null>(null);
  const multiDetails = useMultiLocationFeature();
  const { notificationFilters } = useNotificationFiltersStore('notificationFilters');
  const selectedLocationIds = multiDetails.isMultiLocationFeature ? multiSelectedLocationIds : [locationId];
  const { status, replay, latency, error } = useShellPingPong();
  const [notificationTrayIsOpen, setNotificationTrayIsOpen] = useState(false);

  useEffect(() => {
    if (!firestoreDbQuery.db || !userId || !selectedLocationIds.length) return;

    const collection_ = collection(firestoreDbQuery.db, 'user_notification');
    const unreadCountQuery = query(
      collection_,
      where('userId', '==', userId),
      where('locationId', 'in', selectedLocationIds),
      where('isDesktopNotification', '==', true),
      where('hasRead', '==', false)
    );

    const unsubscribe = onSnapshot(
      unreadCountQuery,
      function onNext(querySnapshot) {
        setUnreadCount(querySnapshot.size);
      },
      function onError(e) {
        sentry.error({
          error: e,
          topic: 'notifications',
        });
        console.error('Failed to fetch notification count from firestore', e);
        setUnreadCount(null);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [firestoreDbQuery?.db, userId, selectedLocationIds.join(',')]);

  useEffect(() => {
    if (!firestoreDbQuery.db || !userId || !selectedLocationIds.length) return;

    const conditions = [
      where('userId', '==', userId),
      where('locationId', 'in', selectedLocationIds),
      where('isDesktopNotification', '==', true),
      limit(100),
      orderBy('createdAt', 'desc'),
    ];

    if (notificationFilters.length) conditions.push(where('type', 'in', notificationFilters));
    const notificationListQuery = query(collection(firestoreDbQuery?.db, 'user_notification'), ...conditions);
    const unsubscribe = onSnapshot(
      notificationListQuery,
      function onNext(querySnapshot) {
        setFirebaseError(null);
        const notifications: NotificationDoc[] = [];

        querySnapshot.forEach((doc) => {
          const { createdAt, expireAt, ...rest } = doc.data();
          notifications.push({
            ...rest,
            // firestore dates do not display in the browser, so we need to format them
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore these types are wrong in schema they are Firestore timestamps, not strings
            createdAt: (createdAt as Timestamp).toDate(),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore these types are wrong in schema they are Firestore timestamps, not strings
            expireAt: (expireAt as Timestamp).toDate(),
          });
        });

        setData(notifications);
      },
      function onError(e) {
        sentry.error({
          error: e,
          topic: 'notifications',
        });
        console.error('Failed to fetch notification documents from firestore', e);
        setFirebaseError(e);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [firestoreDbQuery.db, userId, notificationFilters.length, selectedLocationIds.join(',')]);

  const markThreadNotificationsAsRead = (threadId: string, locationId: string) => {
    if (!firestoreDbQuery.db || !threadId) return;
    const unreadThreadQuery = query(
      collection(firestoreDbQuery.db, 'user_notification').withConverter(converter),
      where('userId', '==', userId),
      where('locationId', '==', locationId),
      where('hasRead', '==', false),
      where('alert.payload.threadIds', 'array-contains', threadId)
    );
    const unsubscribe = onSnapshot(unreadThreadQuery, (querySnapshot) => {
      // if there aren't any unread notifications for this thread, we don't need to do anything
      if (querySnapshot.size === 0) return;
      const batch = writeBatch(firestoreDbQuery.db);
      querySnapshot.forEach((doc) => {
        batch.update(doc.ref, { hasRead: true } as Pick<NotificationDoc, 'hasRead'>);
      });
      batch.commit().finally(unsubscribe);
    });
  };

  const toggleNotificationAsRead = async (notificationId: string, hasRead: boolean) => {
    if (!notificationId || !firestoreDbQuery?.db) return;
    const docToUpdateRef = doc(firestoreDbQuery?.db, 'user_notification', notificationId);
    const docSnap = await getDoc(docToUpdateRef);

    if (!docSnap.exists()) {
      console.error('Unable to find document');
    } else {
      await updateDoc(docToUpdateRef, { hasRead: !hasRead } as Pick<NotificationDoc, 'hasRead'>);
    }
  };

  const value = useMemo(
    () =>
      ({
        emit: emitter.emit,
        emitter,
        notifications: data,
        firebaseError,
        isLoading: data === null,
        markThreadNotificationsAsRead,
        toggleNotificationAsRead,
        unreadCount,
        status,
        replay,
        latency,
        error,
        notificationTrayIsOpen,
        setNotificationTrayIsOpen,
        maxLocationsSelected: selectedLocationIds.length > MAX_SELECTABLE_LOCATIONS,
      } satisfies ContextValue),
    [emitter, data, firebaseError, status, replay, latency, error, notificationTrayIsOpen]
  );

  useHandleEmptyQueue();

  return (
    <NotificationContext.Provider value={value}>
      <TransientQueueProvider>{children}</TransientQueueProvider>
      {soundOptions.map((sound) => (
        <audio key={sound.id} className='message-notification-audio' id={sound.id} src={sound.file} preload='none' />
      ))}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => useContext(NotificationContext);

const useHandleEmptyQueue = () => {
  const shell = useShell();
  useEffect(() => {
    if (shell.isShell) {
      const id = 'empty-shell' + Math.random().toString();
      const handler = () => {
        shell.emit?.('notifications:hide', undefined);
      };
      shell.on?.('notifications:empty', handler, id);
      return () => {
        shell.off?.('notifications:empty', handler, id);
      };
    }
    return;
  }, []);
};

//This sends a ping message to the main process, which sends a ping message to the notifications window
//Then the notifications window sends a pong message back to the main process, which sends it back to here
//This will verify that communication between the notifications window on this window is working correctly
//This can also be useful for testing what can successfully be serialized through the IPC process
//The payload passed in here will be relayed as-is back to the pong handler
type Status = 'not_applicable' | 'idle' | 'waiting' | 'timeout' | 'failed' | 'received';
const latencyThreshold = 1000; // 1 second should be plenty of time to communicate to ipc and back
const useShellPingPong = () => {
  const shell = useShell();
  const [sentAt, setSentAt] = useState<number>();
  const [receivedAt, setReceivedAt] = useState<number>();
  const [error, setError] = useState<string>();
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const latency = useMemo(
    () => (receivedAt && sentAt ? receivedAt - sentAt : sentAt ? Date.now() - sentAt : undefined),
    [receivedAt, sentAt]
  );
  const status = ((): Status => {
    if (error) {
      return 'failed';
    }
    if (!shell.isShell) {
      return 'not_applicable';
    }
    if (!sentAt) {
      return 'idle';
    }
    if (!receivedAt) {
      if (Date.now() - sentAt > latencyThreshold) {
        return 'timeout';
      }
      return 'waiting';
    }
    return 'received';
  })();

  const replay = useCallback(() => {
    console.log('Checking Notification Window Communication');
    try {
      shell?.emit?.('debug:ping', {
        message: 'initializing communication with notification window',
      });
      timeout.current = setTimeout(() => {
        setError('Failed to initialize communication with notification window');
        console.error('Failed to initialize communication with notification window');
        sentry.error({
          error: new Error('Failed to initialize communication with notification window'),
          topic: 'notifications',
        });
      }, latencyThreshold);
      setSentAt(Date.now());
      setReceivedAt(undefined);
      setError(undefined);
    } catch (e: unknown) {
      setError(e instanceof Error ? e.message : 'Failed to initialize communication with notification window');
      console.error('Failed to initialize communication with notification window', e);
    }
  }, [shell]);

  useEffect(() => {
    const id = 'ping-pong' + Math.random().toString();

    const handler: IPCRendererCallback<'debug:pong'> = (_e, payload) => {
      console.info('Communication with notification window initialized', { payload });
      setReceivedAt(Date.now());
      setError(undefined);
      clearTimeout(timeout.current);
    };
    shell.on?.('debug:pong', handler, id);

    return () => {
      shell.off?.('debug:pong', handler, id);
      clearTimeout(timeout.current);
    };
  }, [shell]);

  useEffect(() => {
    if (status === 'idle') {
      replay();
    }
  }, [replay, status]);

  return useMemo(() => {
    return {
      status,
      replay,
      latency,
      error,
    };
  }, [status, replay, latency, error]);
};
