import { StateCreator } from 'zustand';
import { LocationsApi, LocationsTypes } from '@frontend/api-locations';
import { isWeaveUser } from '@frontend/auth-helpers';
import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';
import { UsersTypes } from '@frontend/user-helpers';
import { useLocalizedQueryScoper } from './legacy-location-scoper';
import { useMultiLocationCheck } from './use-multi-location-check';

interface LocationSummary {
  active: boolean;
  parentID: string;
  phoneTenantID: string;
  locationID: string;
  name: string;
  slug: string;
  type?: string;
}

type LocationResult = {
  locationID: LocationSummary['locationID'];
  name: LocationSummary['name'];
  parentID: string;
  selected?: boolean;
};

type LocationIdNameMapPayload = {
  locationId: string;
  locationName: string;
};

/**
 * @deprecated
 */
export type ExtendedLocationResult = LocationResult & { children?: ExtendedLocationResult[]; indent?: boolean };

export interface LocationStore {
  registeredLocationIds: string[];
  // This list will only be populated for non-privileged (non-Weave) users
  registeredLocations: ExtendedLocationResult[];
  locationId: string;
  multiSelectedIds: string[];
  isMultiLocation: boolean;
  isParentLocation: boolean;
  locationUsers: UsersTypes.UserProfile[];
  locationData: LocationsTypes.Location | null;
  _internalLocationIdNameMap: Map<string, string>;
  childLocations: LocationsTypes.Location[] | null;
  isConfiguredLocationData: boolean;
  hasOnlyOneLocation: () => boolean;
  /**
   * @deprecated Please migrate away from this method ASAP - use `getLocationName` from `useAppScopeStore` instead
   */
  getMultiLocationName: (locationId: string) => string | null | undefined;
  getOneSelectedMultiLocationName: () => string;
  setRegisteredLocationIds: (ids: string[]) => void;
  setRegisteredLocations: (locations: ExtendedLocationResult[]) => void;
  _internalSetLocationIdNameMap: (payload: LocationIdNameMapPayload) => void;
  setLocationUsers: (locationUsers: UsersTypes.UserProfile[]) => void;
  setLocationInfo: (info: {
    location: LocationsTypes.Location;
    childLocations: LocationsTypes.Location[];
    isMultiLocation: boolean;
    isParentLocation: boolean;
  }) => void;
  setMultiSelectedIds: (ids: string[]) => void;
  setIsConfiguredLocationInfo: (isConfiguredLocationData: boolean) => void;
}

const storeDefinition: StateCreator<
  LocationStore,
  [['zustand/subscribeWithSelector', never], ['zimmer', never], ['zustand/devtools', never]]
> = (set, get) => ({
  registeredLocationIds: [],
  registeredLocations: [],
  _internalLocationIdNameMap: new Map(),
  locationId: '',
  isMultiLocation: false,
  isParentLocation: false,
  locationData: null,
  locationUsers: [],
  childLocations: null,
  multiSelectedIds: [],
  isConfiguredLocationData: false,
  hasOnlyOneLocation: () => {
    const { registeredLocationIds } = get();
    return registeredLocationIds.length === 1 && !isWeaveUser();
  },
  setRegisteredLocationIds(ids) {
    set({ registeredLocationIds: ids });
  },
  setRegisteredLocations(locations) {
    set({ registeredLocations: locations });
  },

  getMultiLocationName: (locationId: string) => {
    const { registeredLocationIds, registeredLocations } = get();
    if (registeredLocationIds.length === 1 && !isWeaveUser()) return null;

    if (get()._internalLocationIdNameMap.has(locationId)) return get()._internalLocationIdNameMap.get(locationId);

    if (locationId === get().locationId) {
      return get().locationData?.Name;
    }

    const location = registeredLocations.find((location) => location.locationID === locationId);

    if (location) return location.name;
    return null;
  },
  setLocationInfo({ location, childLocations, isMultiLocation, isParentLocation }) {
    const { multiSelectedIds, registeredLocationIds } = get();
    set(
      {
        locationId: location.LocationID,
        locationData: location,
        isMultiLocation,
        isParentLocation,
        childLocations,
        locationUsers: [],
        multiSelectedIds:
          !multiSelectedIds.length || (registeredLocationIds.length === 1 && registeredLocationIds[0] !== 'weave')
            ? [location.LocationID]
            : multiSelectedIds,
      },
      false,
      { type: 'SET_LOCATION_INFO' }
    );
  },
  getOneSelectedMultiLocationName: () => {
    const singleMultiSelectedId = get().multiSelectedIds[0];
    return singleMultiSelectedId ? get().getMultiLocationName(singleMultiSelectedId) ?? '' : '';
  },
  _internalSetLocationIdNameMap: (payload: LocationIdNameMapPayload) => {
    set((state) => {
      state._internalLocationIdNameMap.set(payload.locationId, payload.locationName);
    });
  },
  setLocationUsers: (locationUsers: UsersTypes.UserProfile[]) => set({ locationUsers }, false, 'SET_LOCATION_USERS'),
  setIsConfiguredLocationInfo: (isConfiguredLocationData: boolean) =>
    set({ isConfiguredLocationData }, false, 'SET_IS_CONFIGURED_LOCATION_DATA'),
  setMultiSelectedIds: (ids: string[]) => set({ multiSelectedIds: ids }, false, 'SET_MULTI_SELECTED_IDS'),
});

const useStore = createStoreWithSubscribe<LocationStore>(storeDefinition, {
  name: 'LocationStore',
  trace: true,
  serialize: {
    options: {
      map: true,
    },
  },
});

const useShallowStore = createShallowStore(useStore);

/**
 * @deprecated Please migrate away from this hook ASAP. It isn't reliable in NWX
 *
 */
//@ts-expect-error. These types are crazy, I don't know
export const useLocationDataStore: typeof useStore = () => {
  const scope = useLocalizedQueryScoper();
  const res = useStore();
  return useLocalizedQueryScoperOverride(scope?.locationId || res.locationId, res, {
    enabled: !!scope?.locationId,
  });
};

/**
 * @deprecated Please migrate away from this hook ASAP. It isn't reliable in NWX
 */
export const useLocationDataShallowStore: typeof useShallowStore = (...args) => {
  const scope = useLocalizedQueryScoper();
  const res = useShallowStore(...args);
  return useLocalizedQueryScoperOverride(scope?.locationId || res.locationId, res, {
    enabled: !!scope?.locationId,
  });
};

/**
 *
 * this basically mimics what we do in `configue-location.ts` in the weave app when a new location is selected
 */
export const useLocalizedQueryScoperOverride = (
  locationId: string,
  store: LocationStore,
  { enabled }: { enabled: boolean }
) => {
  const { data: locationData } = LocationsApi.useQueryLocationData(locationId, {
    enabled: enabled && !!(locationId && store.locationId && store.locationData),
    notifyOnChangeProps: ['data'],
  });

  const { isMultiLocation, isParentLocation, childLocations } = useMultiLocationCheck(
    {
      locationId: locationId,
      parentId: locationData?.ParentID ?? store.locationData?.ParentID ?? null,
    },
    {
      enabled,
      notifyOnChangeProps: ['data'],
    }
  );

  /** Overwrite the legacy location store with scoped location data if applicaable */
  /** this may cause a weird state for a moment while it fetches is no location available */

  if (enabled) {
    if (store?.locationId && locationData) {
      store.locationId = locationId;
    }
    if (store.locationData && locationData) {
      store.locationData = locationData;
    }
    store.isMultiLocation = isMultiLocation;
    store.isParentLocation = isParentLocation;
    store.childLocations = childLocations;
  }
  return store;
};
