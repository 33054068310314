function isCurrentDateBetween(startMonth: number, startDay: number, endMonth: number, endDay: number) {
  const now = new Date();
  const currentYear = now.getFullYear();
  const startDate = new Date(currentYear, startMonth - 1, startDay);
  const endDate = new Date(currentYear, endMonth - 1, endDay);

  // Adjust for ranges that cross the New Year (e.g., Dec 1 to Jan 1)
  if (endMonth < startMonth) {
    const endDateNextYear = new Date(currentYear + 1, endMonth - 1, endDay);
    return now >= startDate && now <= endDateNextYear;
  }

  return now >= startDate && now <= endDate;
}

export const isThanksgivingPeriod = isCurrentDateBetween(11, 15, 12, 1);
export const isChristmasPeriod = isCurrentDateBetween(12, 1, 1, 1);
