import { createContext, useCallback, useContext, useMemo, useEffect } from 'react';
import { http } from '@frontend/fetch';

type ContextValue =
  | {
      id: string;
      scope: () => void;
      unscope: () => void;
      locationId: string;
    }
  | undefined;

const Context = createContext<ContextValue>(undefined);

type Props = {
  children: React.ReactNode;
  locationId: string;
};
export const LocalizedQueryScoper = ({ locationId, children }: Props) => {
  const randomId = useMemo(() => Math.random().toString(), []);
  const scope = useCallback(() => {
    const headers = new Headers();
    headers.set('Location-Id', locationId);
    http.temporaryHeaders.push({ id: randomId, headers });
  }, [randomId, locationId]);
  const unscope = useCallback(() => {
    http.temporaryHeaders = http.temporaryHeaders.filter((header) => header.id !== randomId);
  }, [randomId]);

  useEffect(() => {
    //just in case a scope is left open for some reason when this unmounts
    return () => {
      unscope();
    };
  }, []);

  const value = useMemo(
    () => ({
      scope,
      unscope,
      id: randomId,
      locationId,
    }),
    [randomId, locationId, scope, unscope]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useLocalizedQueryScoper = () => {
  return useContext(Context);
};
