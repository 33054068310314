import { useEffect } from 'react';
import { css } from '@emotion/react';
import { TableInstance } from 'react-table';
import { IconButton, SearchField, SecondaryButton } from '../../';
import { useFormField } from '../../';
import { useDebouncedValue, useDidUpdate, useThemeValues } from '../../../hooks';
import { SearchIcon } from '../../../icon';
import { useStyles } from '../../../use-styles';
import { TableData } from '../table-data-type';
import { GlobalSearchConfig } from '../table-types';

interface GlobalFilterComponentProps<T extends TableData> {
  tableInstance: TableInstance<T>;
  manualGlobalSearch?: boolean;
  globalSearchConfig?: GlobalSearchConfig;
  setMobileGlobalSearch: (mobileGlobalSearch: boolean) => void;
  mobileGlobalSearch: boolean;
}

export const GlobalFilterComponent = <T extends TableData>({
  tableInstance,
  manualGlobalSearch,
  globalSearchConfig,
  setMobileGlobalSearch,
  mobileGlobalSearch,
}: GlobalFilterComponentProps<T>) => {
  const { spacing } = useThemeValues();
  const { setGlobalFilter, gotoPage, isMobile, tableTrackingIds } = tableInstance;
  const notificationBadgeStyles = useStyles('TableCommonStyles', 'filterNotificationBadgeStyles');

  const notificationStyle = (showNotification: boolean) => [
    notificationBadgeStyles,
    css`
      ::after {
        opacity: ${showNotification ? 1 : 0};
      }
    `,
  ];

  const globalSearch = useFormField({ type: 'text', value: globalSearchConfig?.initialValue }, [
    globalSearchConfig?.initialValue,
  ]);
  const debouncedValue = useDebouncedValue(globalSearch.value, globalSearchConfig?.debounceDelay ?? 300);

  useDidUpdate(() => {
    if (manualGlobalSearch && globalSearchConfig?.searchHandler) {
      globalSearchConfig.searchHandler(debouncedValue);
    } else {
      setGlobalFilter(debouncedValue);
      gotoPage(0);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (!globalSearchConfig?.initialValue) return;

    if (globalSearchConfig?.searchHandler) {
      globalSearchConfig.searchHandler(globalSearchConfig.initialValue);
      return;
    }

    setGlobalFilter(globalSearchConfig.initialValue);
    gotoPage(0);
  }, []);

  return (
    <>
      {isMobile && !mobileGlobalSearch ? (
        <IconButton
          label='Global Table Search'
          css={[
            notificationStyle(!!debouncedValue.length),
            css`
              margin-left: ${spacing(1)};
            `,
          ]}
          onClick={() => setMobileGlobalSearch(true)}
        >
          <SearchIcon color='light' />
        </IconButton>
      ) : (
        <div
          css={
            isMobile
              ? css`
                  width: 100%;
                  display: flex;
                  align-items: center;
                `
              : [
                  css`
                    width: 33.33%;
                    margin-right: ${spacing(1)};
                    min-width: 200px;
                    max-width: 350px;
                  `,
                  globalSearchConfig?.position === 'left' &&
                    css`
                      margin-right: auto;
                    `,
                ]
          }
        >
          <div
            css={css`
              flex: 1;
            `}
          >
            <SearchField
              placeholder={globalSearchConfig?.placeholder}
              label={globalSearchConfig?.label}
              name='Global Table Search'
              data-trackingid={tableTrackingIds.globalSearch}
              {...globalSearch}
            />
          </div>
          {isMobile && (
            <SecondaryButton
              css={css`
                margin-left: ${spacing(0.5)};
                height: 100%;
              `}
              size='tiny'
              onClick={() => setMobileGlobalSearch(false)}
            >
              Done
            </SecondaryButton>
          )}
        </div>
      )}
    </>
  );
};
