import { MutationOptions, UseQueryOptions } from 'react-query';
import { HttpError } from '@frontend/fetch';
import { useMutation, useQuery } from '@frontend/react-query-helpers';
import { SchemaPlatformLocationFeature, SchemaSubscriptionManagerService } from '@frontend/schema';
import {
  IsMultiEligibleForSelfUpgradeOrTrialRequest,
  IsMultiEligibleForSelfUpgradeOrTrialResponse,
  MultiSubscribeRequest,
  MultiSubscribeResponse,
  MultiTrialSignupRequest,
  MultiTrialSignupResponse,
  GetTrialHistoryForMultiRequest,
  GetTrialHistoryForMultiResponse,
  GetSubscriptionAddOnsStatusesForMultiRequest,
  GetSubscriptionAddOnsStatusesForMultiResponse,
} from './types';

const HOURS_IN_MS = 1000 * 60 * 60;

export const freeTrialSubscriptionQueryKeys = {
  subscriptionBaseQuery: ['subscription', 'feature'],
  multiSelfUpgradeOrFreeTrialEligibility: ({
    featureEnum,
    locationIds,
  }: IsMultiEligibleForSelfUpgradeOrTrialRequest) => [
    ...freeTrialSubscriptionQueryKeys.subscriptionBaseQuery,
    'free-trial',
    'eligibility',
    featureEnum,
    ...locationIds,
  ],
  trialExpiringFeatures: ({ locationIds }: GetTrialHistoryForMultiRequest) => [
    ...freeTrialSubscriptionQueryKeys.subscriptionBaseQuery,
    'free-trial',
    'trial-expiring',
    ...locationIds,
  ],
  multiSubscriptionAddOns: ({ locationIds }: GetSubscriptionAddOnsStatusesForMultiRequest) => [
    ...freeTrialSubscriptionQueryKeys.subscriptionBaseQuery,
    'add-ons',
    ...locationIds,
  ],
};

export const useFreeTrialMultiSignUp = (
  options?: MutationOptions<MultiTrialSignupResponse, unknown, MultiTrialSignupRequest>
) => {
  return useMutation({
    mutationFn: (request: MultiTrialSignupRequest) => SchemaPlatformLocationFeature.MultiLocationsTrialSignup(request),
    ...options,
  });
};

export const useSelfUpgradeOrFreeTrialEligibility = (
  request: IsMultiEligibleForSelfUpgradeOrTrialRequest,
  options?: UseQueryOptions<IsMultiEligibleForSelfUpgradeOrTrialResponse>
) => {
  return useQuery({
    queryFn: async () => await SchemaSubscriptionManagerService.IsMultiEligibleForSelfUpgradeOrTrial(request),
    queryKey: freeTrialSubscriptionQueryKeys.multiSelfUpgradeOrFreeTrialEligibility(request),
    enabled: !!(request.locationIds.length > 0 && request.featureEnum),
    cacheTime: 12 * HOURS_IN_MS,
    staleTime: 12 * HOURS_IN_MS,
    ...options,
  });
};

export const useFeatureMultiSubscribe = (
  options?: MutationOptions<MultiSubscribeResponse, HttpError, MultiSubscribeRequest>
) => {
  return useMutation({
    mutationFn: (request: MultiSubscribeRequest) =>
      SchemaSubscriptionManagerService.MultiSubscribe(request, {
        headers: {
          // TODO: Milind remove later after debugging
          'X-Weave-Debug-Id': 'temp-plg-ysg-ms',
        },
      }),
    ...options,
  });
};

export const useTrialExpiringFeatures = (
  request: GetTrialHistoryForMultiRequest,
  options?: UseQueryOptions<GetTrialHistoryForMultiResponse>
) => {
  return useQuery({
    queryFn: async () => await SchemaPlatformLocationFeature.GetTrialHistoryForMulti(request),
    queryKey: freeTrialSubscriptionQueryKeys.trialExpiringFeatures(request),
    enabled: !!request.locationIds.length,
    cacheTime: 12 * HOURS_IN_MS,
    staleTime: 12 * HOURS_IN_MS,
    ...options,
  });
};

export const useMultiGetAddOnsSubscription = (
  request: GetSubscriptionAddOnsStatusesForMultiRequest,
  options?: UseQueryOptions<GetSubscriptionAddOnsStatusesForMultiResponse>
) => {
  return useQuery({
    queryKey: freeTrialSubscriptionQueryKeys.multiSubscriptionAddOns(request),
    queryFn: async () => await SchemaSubscriptionManagerService.GetSubscriptionAddOnsStatusesForMulti(request),
    enabled: !!request.locationIds.length,
    ...options,
  });
};
